import React from 'react'
import { useNavigate, useParams } from "react-router-dom";
import back from "../../assets/Icons/Back.png";
const TermsOfUse = () => {
    const { dentalOfficeName } = useParams();
    const navigate = useNavigate();
  return (
    <div>
    <div    >
    <div className="fixed top-0 left-0 z-50 w-full bg-slate-50  flex items-center p-2 pt-3 ">
          <button
            onClick={() => {
              navigate(`/${dentalOfficeName}/menu`);
            }}
          >
            <img src={back} alt="back" className="w-8 h-8" />
          </button>
          <p className=" font-medium text-base px-2">Privacy Policy</p>
        </div>
      <div className="px-4">
         <div className="">
          <h1 className="font-bold text-center pt-16 text-xl">TERMS OF USE</h1>
          <div className="text-base flex justify-around">
            Welcome to the Terms of Use (the “Terms”) of Toothlens, an online
            platform offering dental wellness information and pre-diagnostic
            services (the “Services”). Your access and use of the Services are
            contingent upon your acceptance of the terms and conditions
            outlined herein. By accepting the Terms, you consent to be bound
            by them. We request you to read the Terms carefully as they govern
            your relationship with our Company and the use of our Services.
            Please be advised that these Terms are subject to periodic
            updates, amendments, modifications, or revisions. Therefore, it is
            important that you refer to these Terms from time to time.
          </div>
          <div className="font-bold my-3">1. Background</div>
        </div>
        <div className='text-base '>
          1.1. Toothlens Inc. is a company registered in the United States of
          America (the “USA/US”) having its registered office at 651N, Broad
          Street, Middletown Delaware 19709 USA (the “Toothlens” or “Company”
          or “We/we” or “Us/us” or “Our/our”).
        </div>
        <div>
          1.2. The website, smartcheck.toothlens.com, together with its
          smartphone application (collectively referred to as “Application” /
          “Platform”) are owned, managed and operated by the Company.
        </div>

        <div>
          1.3. The Company is in the business of providing technology-related
          solutions in the healthcare domain and in furtherance thereto, has
          developed an artificial intelligence (“AI”) powered pre- diagnostic
          tool (“SmartCheck” or “Services”) which can be accessed through the
          Application, (a) upon providing login credentials (the “User” or
          “user”), and (b) uploading images of the user’s teeth onto the
          Platform.
        </div>
        <div className="font-bold my-3">2. Applicability of Terms</div>
        <div>
          2.1. The Terms herein set out the terms and conditions for availing
          the Services offered by the Company by using the Application.
        </div>
        <div>
          2.2. For the purpose of these Terms, along with any amendments to
          the same, and wherever the context so requires “You/you”,
          “Your/your” or “Yourself/yourself”, shall mean any natural or legal
          person who has agreed to become a user of the Application by
          accessing it or installing it on a smartphone application.
        </div>
        <div>
          2.3. You hereby agree and acknowledge that by accepting the Terms,
          you expressly confirm that you have read, understood and agree to
          comply with and be bound by its terms and conditions.
        </div>
        <div>
          In the event that you do not agree to any or all of the Terms, or
          are under the age of 18 (eighteen) years, you are requested not to
          access and use any of the Services offered by the Company and/or the
          Application.
        </div>
        <div>
          You understand that the Company may amend these Terms from time to
          time as it may deem fit and necessary. Any amendment to the Terms
          shall be effective upon the Company’s posting of such updated Terms
          on the Application and we will ask you to acknowledge and accept the
          modified Terms before accessing the Services. In case of any
          material adversity, such as deteriorated entitlements or higher
          costs, we offer users to opt out by writing to us at
          support@toothlens.com.
        </div>
        <div className="font-bold my-3">3. Registration</div>
        <div>
          3.1. Wherever applicable, you agree to sign up and create an
          authenticated account (the “Account”) by providing all required
          information in order to access or use the Services (the “Sign-up
          Process”). To make your Account on the Application, you shall create
          a username using your email id and a password. You shall also
          provide us with any other necessary information for establishing
          your Account.
        </div>
        <div>
          3.2. You agree and acknowledge that you shall: (a) Create only 1
          (one) Account using your credentials unless approved expressly in
          writing by the Company; (b) Provide accurate, truthful, updated and
          complete information when creating your Account and in all your
          dealings with the Company; (c) Maintain the security of your Account
          by not sharing your password with others and by immediately changing
          your password if you suspect any unauthorized access to your
          Account; and (d) Promptly notify the Company if you discover or
          otherwise suspect any security breaches relating to the Application.
        </div>
        <div>
          3.3. You agree and acknowledge that any and all activity undertaken
          by you from the Account shall be your sole responsibility and the
          Company shall not be responsible or liable for the same in any
          manner towards you or any other third party.
        </div>
        <div>
          3.4. In the event, that the Company finds the information, so
          provided by you, to be untrue, inaccurate, outdated or incomplete,
          then it shall be entitled to terminate your Account and refuse
          current or future use of any and/or all of the Services and you
          shall be liable to indemnify and hold harmless the Company and/or
          its affiliates, directors, employees, and agents to the extent of
          the loss incurred.
        </div>
        <div>
          3.5. The Company will not be liable for any loss or damage arising
          from the failure on your part to comply with the provision of these
          Terms. Further, you agree to indemnify and hold the Company and/or
          its affiliates, directors, employees and agents harmless from any
          claims or damages suffered by the Company and/or its affiliates,
          directors, employees and agents due to any use of the Account.
        </div>
        <div className="font-bold my-3">4. SmartCheck</div>
        <div>
          4.1. The Application is an AI-powered “dental wellness screening
          tool” which is essentially a pre- diagnostic self-check tool to help
          you understand your overall dental health and track your oral
          hygiene (the “Mobile AI app./ Platform”) by using images captured by
          you on your smartphone camera. To upload these images onto the
          Application, you are required to log into your Account by using your
          username and password created at the time of registration.
        </div>
        <div>
          4.2. Upon successfully uploading the images, the Application employs
          its advanced image recognition technology to perform a comprehensive
          dental screening. Specifically, the Application screens the images
          for common dental issues such as tooth decay, visible plaque, and
          root exposure.
        </div>
        <div>
          4.3. Following a preliminary screening, a &quot;SmartCheck Oral
          Health Report&quot; (the “Report”) is generated, providing a summary
          of the total number of indicative dental issues recognized by the
          Application during the screening process. The number of issues
          reported determines a user&#39;s low, medium, or high-risk profile.
        </div>
        <div>
          4.4. The Report further includes &quot;Dental Wellness
          Information&quot; to promote or encourage you to (a) mitigate the
          risk of dental concerns, or (b) improve the management of them.
          Further, the Application also sends periodic reminders to you to
          upload their images and track the progress of your oral hygiene.
        </div>
        <div>
          4.5. To ensure accuracy, the Application undergoes rigorous quality
          control measures. Our team of algorithm and image recognition
          experts collaborate with dental professionals to maintain the
          reliability of the Application. However, the accuracy of the Report
          relies solely on the quality of the images that you upload onto the
          Platform. You therefore understand and acknowledge that the
          screening process is conducted on your smartphone-generated images,
          which may not be an actual representation of any discomfort you may
          experience and shall not be considered to be a substitute for a
          consultation with a qualified dental practitioner.
        </div>
        <div>
          4.6. The Application only provides an indication of your dental
          health and does not replace traditional dental risk evaluation
          methods, therefore the Report should be reviewed by a qualified
          dentist. Any decision that you take to discuss your dental health,
          personal risk factors and your Report with your doctor, shall be
          your sole responsibility and you shall not hold the Company liable
          for any grievances arising therefrom.
        </div>
        <div className="font-bold my-3">5. Health Follow-Up Policy</div>
        <div>
          5.1. The Company, in furtherance to continually improving our
          services, performs research activities from time to time. As part of
          the research activities, we may perform checks on the photographs
          shared by you on the Platform to verify the accuracy of the
          Application.
        </div>
        <div>
          5.2. In case we think that it may benefit your health, we will share
          the outcomes of those checks with you and contact you outside of our
          regular automated communication.
        </div>

        <div className="font-bold my-3">6. Privacy Policy</div>
        <div>
          6.1. The Company respects your privacy and has accordingly
          formulated a privacy policy, in compliance with the applicable laws
          (the “Privacy Policy”).
        </div>
        <div>
          6.2. The use of any personal information by the Company shall be
          governed by the Privacy Policy as provided on the website of the
          Company and may be subject to amendment from time to time in order
          to ensure compliance with law.
        </div>
        <div>
          6.3. In accordance with the law for the time being in force and
          subject to your consent, the Company may make available to the
          medical practitioners / doctors information relating to you for any
          legally justifiable reason(s) relating to insurance.
        </div>

        <div className="font-bold my-3">7. Intellectual Property</div>
        <div>
          7.1. The name “Toothlens” and any other logos and service marks are
          trademarks owned by the Company. The technology, computer programs,
          codes, images, domain name, the look and feel of the Application and
          all information available thereon are all the exclusive intellectual
          property of the Company (the “Content/content”).
        </div>
        <div>
          7.2. The US Copyright laws protect the content and design of the
          Application. Any unauthorized reproduction, republication,
          distribution, display, transmission, sale or any other use and/or
          duplication of any material available on the Application without
          prior written consent from the Company shall be in violation of the
          relevant intellectual property laws.
        </div>
        <div>
          7.3. The Company reserves the right to initiate and pursue any
          necessary legal and/or other action against any such user for
          infringement of the Company’s intellectual property.
        </div>
        <div>
          7.4. You may view, download, display and print a single copy of the
          Report on a single computer or mobile device for personal,
          non-commercial use only, as long as: (a) You do not alter or modify
          these materials in any way; and (b) You include all the applicable
          notices and disclaimers (including copyright notices). (c) You do
          not use the materials in a way that suggests an association with the
          Company or its affiliates. (d) You understand and agree that these
          materials’ titles shall not be passed to you or any other user.
        </div>

        <div className="font-bold my-3">8. User Obligations</div>

        <div>
          8.1. You will be eligible to use the Services only by registering
          with the Company and fulfilling the following qualifications: (a)
          You should be above the legal age of 18 (eighteen) years; (b) You
          have the legal capacity to enter into a contract and are not
          specifically disqualified under law.
        </div>

        <div>
          8.2. You undertake not to: (a) cut, copy, distribute, modify,
          recreate, reverse engineer, post, publish or create derivative works
          from, transfer, or sell any information or software obtained from
          the Application. For the removal of doubt, it is clarified that
          unlimited or wholesale reproduction, copying of the content or
          software of the Application for personal, non-commercial or
          commercial purposes and unwarranted modification of data and
          information within the content thereof is not permitted. Should you
          wish to engage in one or more such actions, prior written permission
          from us must be obtained; (b) access (or attempt to access) the
          Application and/or the materials or Services by any means other than
          through the interface of the Platform; (c) use the Application in
          any manner that may impair, overburden, damage, disable or otherwise
          compromise the Application; and (d) violate any terms and conditions
          of these Terms or any other policies governing your use of the
          Application including but not limited to our Privacy Policy, Cookie
          Policy and Copyright Notice.
        </div>
        <div>
          8.3. You are also prohibited from violating the security of the
          Application. In case of any violations of the system or network
          security, the Company shall be entitled to take necessary remedial
          actions including but not limited to taking any civil, criminal
          and/or injunctive action.
        </div>
        <div className="font-bold my-3">9. Remedies</div>
        <div>
          9.1. In the event that you are found to have violated any of the
          Terms, the Company reserves the right to do any or all of the
          following: (a) Take any action remediating any violation in such a
          manner that it may deem fit and necessary including all necessary
          legal, civil and criminal actions; (b) Restricting your use of the
          Account and subsequently the Services thereof, either temporarily or
          permanently; (c) Seek indemnification and/or compensation to the
          extent of loss or damage caused to the Company.
        </div>
        <div>
          9.2. You acknowledge that in no event shall the Company be liable
          for any damages whatsoever whether direct, indirect, general,
          special, compensatory, consequential, punitive or incidental,
          arising out of or relating to the conduct of you or anyone else in
          connection with the use of the Services.
        </div>

        <div className="font-bold my-3">10. Termination</div>

        <div>
          10.1 The Terms shall remain in full force and effect unless and
          until the Account is terminated in accordance with the Terms.
        </div>
        <div>
          10.2. You may deactivate the Account and end the registration with
          the Company at any time, for any reason by sending an email to
          support@toothlens.com provided that such termination shall not
          absolve you of any liability towards the Company arising from your
          use of the Services or the Application.
        </div>
        <div>
          10.3. The Company may also suspend or terminate your use of the
          Application, and/or the Account, at any time, for any breach of
          these Terms.
        </div>
        <div>
          10.4. Subject to the laws applicable in the USA, the Company
          reserves the right to maintain, delete or destroy all communications
          or materials posted or uploaded onto the Platform pursuant to its
          internal record retention and/or content destruction policies. After
          such termination, the Company will have no further obligation to
          provide the Services to you.
        </div>
        <div>
          10.5. The Company further reserves the right to terminate these
          Terms or discontinue the Services provided through the Application
          or any portion or feature thereof for any or no reason and at any
          time without any liability towards you or any other third party.
        </div>
        <div>
          10.6. The Company will not be liable to you for any costs, expenses,
          or damages as a result of the termination of these Terms.
        </div>

        <div className="font-bold my-3">11. Disclaimer</div>

        <div>
          11.1. The Services of the Company are being provided solely to
          monitor, track and understand your dental health and oral hygiene.
          No interactions on the Platform create a doctor-patient relationship
          as defined under relevant laws of the USA.
        </div>
        <div>
          11.2. The Services so provided are intended to help understand and
          support self-assessments carried out at your own volition. In the
          context of your dental health and wellness, your preferences should
          always be made in conjunction with your attending a dental health
          care provider.
        </div>
        <div>
          11.3. The Application is not capable of diagnosing or providing
          specific treatment and the Report is not a substitute for
          traditional dental health risk evaluation methods.
        </div>
        <div>
          11.4. Any reliance by you on the Dental Wellness Information so
          provided is at your discretion and risk.
        </div>

        <div>
          11.5. The Report is unique to each user as it is based on the images
          uploaded by you on the Platform. The accuracy of the Application’s
          algorithm and image recognition software depends on the quality of
          the images so uploaded.
        </div>

        <div>
          11.6. The Report is merely indicative and may not be an actual
          representation of any discomfort you experience, therefore, it is
          highly recommended that you discuss the Report with your dentist or
          qualified healthcare practitioner.
        </div>
        <div>
          11.7. The Company will not be liable for any decision you take to
          consult your dentist regarding your dental concerns indicated in the
          Report.
        </div>
        <div className="font-bold my-3">
          12. Representations, Warranties and Guaranties
        </div>
        <div>
          12.1. The Services on the Application are provided to you on an
          “as-is” and “as-available” basis. The Company makes no
          representations or warranties of any kind, express or implied
          (including without limitation warranties of title or
          non-infringement or the implied warranties of merchantability or
          fitness for a particular purpose) as to the content or operation of
          the Platform or any information or Service provided through the
          Application. You expressly agree and acknowledge that you are
          availing the Services at your sole risk.
        </div>
        <div>
          12.2. The Company makes no representations, warranties or
          guarantees, express or implied, regarding the accuracy, reliability
          or completeness of the content of the Application including the
          information contained in the online and in-app library or other
          Services.
        </div>
        <div>
          12.3. The Company does not provide any guarantee that the
          Application shall be free from technological difficulties, including
          but not limited to, the unavailability of information, downtime
          and/or service disruptions. The Company does not guarantee the
          correct functioning of the Services if you use them while you are
          subject to a contra-indication for the use.
        </div>
        <div>
          12.4. The Company engages and employs the best methods to safeguard
          and protect against viruses, bugs, worms, time bombs, trojan horses,
          spyware, et cetera, however, despite such best efforts, the Company
          makes no representation, warranty or guarantee that the Application
          is free from above-mentioned infections and/or other code or
          computer programming routines that contain contaminating or
          destructive properties or that are intended to damage, intercept or
          expropriate any system, data or personal information.
        </div>
        <div className="font-bold my-3">13. Limitation of Liability</div>
        <div>
          13.1. The Company has no responsibility or liability for any action
          that you do or do not take because of or despite the disclaimer
          given in the Application. If you are experiencing a medical
          emergency or have any concerns about your dental health, in any way,
          you should seek immediate medical attention. You should not delay in
          seeking medical advice, stop treatment, or disregard medical advice
          because of the information contained in the Application or the
          Services.
        </div>
        <div>
          13.2. The Company shall not be liable for any loss or damage of any
          nature, to the extent of Services and other information provided
          free of charge on the Application.
        </div>
        <div>
          13.3. The Company will not be liable for any business losses,
          including without limitation, loss or damage to profits, income,
          revenue, use, production, anticipated savings, business contracts,
          commercial opportunities or goodwill, which arise after availing the
          Services, as they are for personal use only.
        </div>
        <div>
          13.4. The Company is not liable for issues arising from your
          interaction with any third parties which are providing certain
          services via the Application, including the ability, intent or
          inability of such third parties to fulfil obligations towards you.
        </div>
        <div>
          13.5. You agree that you shall not bring any claim personally
          against our officers or employees in respect of any losses that you
          may suffer in connection with the Application or these Terms.
        </div>
        <div>
          13.6. The Company shall not be liable for any content you publish on
          social media using a social media plugin on our Application.
        </div>
        <div className="text-bold my-3">14. Grievance Redressal Mechanism</div>
        <div>
          14.1. In the event of any grievances concerning our Services, you
          may send an email to support@toothlens.com or post a mail to 651N,
          Broad Street, Middletown Delaware 19709 USA.
        </div>
        <div className="font-bold my-3">15. Communication</div>
        <div>
          15.1. You understand and agree that the Company may contact you
          through telephone, email, SMS, or any other means of communication
          for the purpose of:
        </div>
        <div>
          (a) Obtaining feedback in relation to the Platform or our Services;
        </div>
        <div>
          (b) To share the outcome of the research activities undertaken by
          the Company using the images shared by you on the Platform;
        </div>
        <div>(c) Resolving any grievances or queries raised by you; and</div>
        <div>
          (d) Providing full cooperation in furtherance of such communication
          by the Company to resolve your grievances.
        </div>
        <div>
          15.2. By submitting suggestions or other feedback regarding our
          Services/Platform, you agree that the Company can use and share such
          feedback for any purpose, without any compensation to you, and the
          Company is under no obligation to keep such feedback confidential.
        </div>
        <div className="font-bold my-3">16. Governing Law and Jurisdiction</div>
        <div>
          16.1. The Terms and the Privacy Policy shall be governed by the
          applicable laws of the USA.
        </div>
        <div>
          16.2. The Courts at Delaware, USA shall have exclusive jurisdiction.
        </div>
        <div className="font-bold my-3">17. Dispute Resolution</div>
        <div>
          17.1. Any dispute remaining unresolved for a period of over [●]
          business days, post-exhausting grievance redressal mechanism may be
          resolved by referring such disputes by mediation under the Delaware
          Rapid Arbitration Act, 2015 (&quot;the “DRAA”).
        </div>
        <div>
          17.2. The order passed by the mediator shall be final and binding on
          the parties, without prejudice to any injunction that either party
          may seek to prevent any imminent threat of violation of their rights
          and/or of any law.
        </div>
        <div className="font-bold my-3">18. Contact</div>

        <div>
          18.1. Any questions regarding the Services or usage of the Platform,
          please contact the Company at support@toothlens.com
        </div>

        <div>
          18.2. For the purpose of verification, you shall be required to
          provide information (including, but not limited to registered email
          address, contact number et cetera) and note the query/grievance
          raised by you.
        </div>

        <div className="font-bold my-3">19. Entire Agreement</div>
        <div>
          19.1. These terms, including the Privacy Policy, constitute the
          entire agreement between you and the Company and govern your use of
          the Application, superseding any prior agreements between you and
          the Company with respect to the Application.
        </div>
      </div>
    </div>
  </div>  )
}

export default TermsOfUse