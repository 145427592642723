import { Navigate, useParams } from "react-router-dom"


const ValidateApp = ({ Component }) => {
    const { dentalOfficeName } = useParams();
    const isAppVerified = localStorage.getItem("appDetails") && dentalOfficeName === localStorage.getItem("dentalOffice")

    return isAppVerified ? Component : <Navigate to={`/${dentalOfficeName}`} />
}

export default ValidateApp


