import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import BackButton from "../../BackButton"
import teeth from "../../../assets/illustrations/LoginTeeth.png"
import eye from "../../../assets/Icons//eye.png"
import "../../../App.css"
import { LoginService } from '../../../Services/APIs/authentication';
import GoogleLogin from './gooleLogin';

const Login = () => {
    const { dentalOfficeName } = useParams();

    const navigate = useNavigate()
    const [isLoading, setLoading] = useState(false)
    const [emailNotFound, setEmailError] = useState(false)
    const [inCorrectPassword, setPasswordError] = useState(false)
    const [isPasswordVisible, setPasswordVisible] = useState(false)
    const [customError, setCustomError] = useState('')

    const validateSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email')
            .matches(/\.[a-zA-Z]{2,}$/, 'Invalid email')
            .required('Email is required'),
        password: Yup.string()
            .min(6, 'Password must be at least 6 characters')
            .required('Password is required'),
    })

    const initialValues = {
        email: '',
        password: '',
    };

    const loginUser = async (values) => {
        setLoading(true)
        setEmailError(false)
        setPasswordError(false)
        setCustomError('')
        await LoginService(values).then((response) => {
            setLoading(false)
            localStorage.setItem('authToken', response.headers.authorization)
            localStorage.setItem('user', JSON.stringify(response.data.data))
            navigate(`/${dentalOfficeName}/onboarding`)
        }).catch(error => {
            setLoading(false)
            if (error.response?.status === 401) {
                setPasswordError(true)
            } else if (error.response?.status === 404) {
                setEmailError(true)
            } else {
                setCustomError(error.response?.data?.messagde || 'An unexpected error occurred')
            }
        })

    }

    return (
        <div className="h-full">
            <BackButton onClick={() => { navigate(`/${dentalOfficeName}`) }} />
            <img src={teeth} alt="teeth" className='saturation absolute top-1 right-0 h-48' />
            <div className="h-4/5 flex flex-col justify-center mx-6">
                <h1 className="font-normal text-39 mx-1">Welcome!</h1>
                <p className="opacity-60 mt-4 mx-1 font-medium text-base">Login to continue</p>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validateSchema}
                    onSubmit={loginUser}
                >

                    {({ isSubmitting, errors, touched }) => (
                        <Form>
                            <div className='mt-5 '>
                                <label htmlFor="email" className='font-normal text-xs mx-2 '>Email ID</label> <br />
                                <Field type="email" id="email" name="email" className={`h-10 text-sm px-2 mt-1 w-full border ${emailNotFound && "border-red-500"} border-black shadow-lg`} placeholder="Enter your email" />
                                <ErrorMessage name="email" component="div" className=" text-red-500 font-medium text-xs m-2" />
                                {emailNotFound && !errors.email && <p className='text-red-500 font-medium text-xs m-2'>This email ID is not registered. </p>}
                            </div>
                            <div className='relative mt-5'>
                                <label htmlFor="password" className=' font-normal text-xs mx-2'>Password</label>
                                <Field type={`${isPasswordVisible ? "text" : "password"}`} id="password" name="password" className={`h-10 text-sm mt-1 px-2 shadow-lg border ${inCorrectPassword && "border-red-500"} border-black w-full`} placeholder="Enter password" />
                                <button type='button' className=' p-2  absolute right-1 top-8' onClick={() => { setPasswordVisible(prevState => !prevState) }}>
                                    <img src={eye} alt="eye" className='h-3' />
                                </button>
                                <ErrorMessage name="password" component="div" className="text-red-500 font-medium text-xs m-2" />
                                {inCorrectPassword && !errors.password && <p className='text-red-500 font-medium text-xs m-2'>You have entered an incorrect password. </p>}
                            </div>
                            <div className='text-center mt-14 '>
                                {customError && <p className="text-red-500 font-medium text-xs m-2"> {customError}</p>}
                                <button type="submit" className="bg-black text-white w-full h-11 font-semibold text-base shadow-2xl" disabled={isLoading} >
                                    {
                                        isLoading ? <div className="loader-container">
                                            <div className="loader"></div>
                                        </div> : <span> Login</span>
                                    }

                                </button>
                            </div>
                            <GoogleLogin/>
                            <div className='text-center m-4'>
                                <button type='button' onClick={() => { navigate(`/${dentalOfficeName}/forgot-password`) }}> Forgot Password? </button>
                            </div>
                        </Form>
                    )}
                </Formik>

            </div>
            <div className="absolute bottom-6  w-screen">
                <p className="text-center text-black ">
                    <span className="opacity-60"> Don’t have a account</span>
                    <button className="font-bold pl-2" onClick={() => navigate(`/${dentalOfficeName}/sign-in`)}>  Sign Up </button>
                </p>
            </div>
        </div>

    )
}

export default Login