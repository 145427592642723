import React from "react";
import cavityLegend from "../../assets/bernImages/cavityBullet.jpg";
import tartarLegend from "../../assets/bernImages/dentalTartarBulletIcon.jpg";
import recessionGum from "../../assets/bernImages/recessionGumBulletIcon.jpg";
import swollenGum from "../../assets/bernImages/swollenGumBulletIcon.jpg";
import { fontStyle,buttonStyle,fontWeight } from "../../Utils/tpscanStyle";

const TpInfoLegends = () => {
  
  return (
    <div className="pb-8 pt-2">
      <div className="flex mx-6 mt-4">
        <img className="h-6" src={cavityLegend} alt="Legends" />
        <span className="mx-4  text-base" style={{...fontWeight,...fontStyle}}>Dental Cavities</span>
      </div>
      <div className="flex mx-6 mt-2">
        <img className="h-6" src={tartarLegend} alt="Legends" />
        <span className="mx-4  text-base" style={{...fontWeight,...fontStyle}}>Plaque and tartar build up</span>
      </div>
      <div className="flex mx-6 mt-2">
        <img className="h-6" src={swollenGum} alt="Legends" />
        <span className="mx-4  text-base" style={{...fontWeight,...fontStyle}}>Swollen gums</span>
      </div>
      <div className="flex mx-6 mt-2">
        <img className="h-6" src={recessionGum} alt="Legends" />
        <span className="mx-4  text-base" style={{...fontWeight,...fontStyle}}>Gum recession</span>
      </div>
    </div>
  );
  
};

export default TpInfoLegends;
