
import { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import profileLogo from "../../assets/illustrations/profile_logo.png"
import close from "../../assets/Icons/close.png"
import { updateProfile, uploadFile } from "../../Services/APIs/profile";
import { FormikDate } from "../../Utils/getFormattedDate";
import { useDispatch, useSelector } from "react-redux";
import { clearProfile, getProfileLogo, noProfile } from "../../Store/profile";

const Edit = ({ setProfile }) => {
    const dispatch = useDispatch()
    const user = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")).user : {}
    const [isLoading, setLoading] = useState(false)
    const [status, setStatus] = useState({ isComplete: false, isSuccess: true })
    const [preview, setPreview] = useState('')
    const [profileError, setProfileError] = useState(false)
    const { imageUrl, isProfileLoading, isProfileExist } = useSelector((state) => state.profileLogo)


    useEffect(() => {
        if (user?.profile) {
            !imageUrl && dispatch(getProfileLogo({ profile: user.profile }))
        } else {
            dispatch(noProfile())
        }

    }, [])




    const validationSchema = Yup.object().shape({
        first_name: Yup.string().required('First name is required'),
        last_name: Yup.string().required('Last name is required'),
        dob: Yup.date()
            .required('Date of Birth is required')
            .test('age', 'Age must be more than 3 years', value => {
                const today = new Date();
                const birthDate = new Date(value);
                const age = today.getFullYear() - birthDate.getFullYear();
                const monthDiff = today.getMonth() - birthDate.getMonth();
                if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
                    return age - 1 > 3;
                }
                return age > 3;
            }),
        gender: Yup.string().required('Gender is required'),
        phone_number: Yup.string().required('Phone number is required'),
        address: Yup.string().required('Address is required'),
        zip_postal_code: Yup.string().matches(/^[0-9]+$/, 'Pincode must contain only digits')
            .min(6, 'Pincode must be 6 digits')
            .max(6, 'Pincode must be 6 digits')
            .required('Pincode is required'),
        city: Yup.string().required('City is required'),
        state: Yup.string().required('State is required'),
        country: Yup.string().required('Country is required'),
    });

    const initialValues = {
        first_name: user?.first_name,
        last_name: user?.last_name,
        dob: user?.dob ? FormikDate(user.dob) : FormikDate(new Date()) ,
        gender: user?.gender,
        phone_number: user?.phone_number,
        address: user?.address,
        zip_postal_code: user?.zip_postal_code,
        city: user?.city,
        state: user?.state,
        country: user?.country,
        profile: user?.profile
    };

    const uploadProfile = async () => {
        const formData = new FormData()
        formData.append("profile_logo", preview)
        const profile = await uploadFile(formData).then((res) => {
            const { profile } = res.data.data
            return profile
        }).catch((error) => {
            setProfileError(true)
            return ''
        });

        return profile

    }

    const handleSubmit = async values => {
        setLoading(true)
        setProfileError(false)
        setStatus({ isComplete: false, isSuccess: true })

        const profile = preview ? await uploadProfile() : values.profile
        const updatedValues = { ...values, profile }

        await updateProfile(updatedValues).then((response) => {
            const { token } = JSON.parse(localStorage.getItem("user"))
            localStorage.setItem("user", JSON.stringify({ user: response.data.data, token }))
            dispatch(clearProfile())
            setStatus({ isComplete: true, isSuccess: true })
            setLoading(false)
            setTimeout(() => {
                setProfile(true)
            }, 2500)
        }).catch((error) => {
            setLoading(false)
            setStatus({ isComplete: true, isSuccess: false })
        })

    }

    const handleProfilefile = (event) => {
        const file = event.target.files[0];
        if (file) {
            setPreview(file);
        }
    }

    const handleLogo = (values, setFieldValue) => {
        if (preview) {
            setPreview('')
        } else if (values.profile) {
            setFieldValue('profile', '')
        }

    }

    return (
        <div className="p-6">



            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}

            >
                {({ setFieldValue, values, isSubmitting, errors, touched }) => (

                    <Form>
                        <div className="flex flex-col items-center flex-grow ">
                            <div className={`${(!preview && !values.profile) && "pe-8"} rounded-full w-44 h-44 relative`}>
                                {
                                    (values.profile || preview) && <button
                                        type="button"
                                        onClick={() => handleLogo(values, setFieldValue)}
                                        className="absolute right-0 bg-gray-300 rounded-full" >
                                        <img src={close} className="h-5 " alt="close" />
                                    </button>
                                }

                                <img
                                    src={preview ? URL.createObjectURL(preview) : (values.profile && isProfileExist) ? imageUrl : profileLogo}
                                    alt="profile_logo"
                                    className={` w-full h-full object-contain rounded-full`} />
                            </div>
                            <label htmlFor="profile" className=" font-medium">Edit Profile Photo </label>
                            <input type="file" id="profile" accept="image/*" onChange={handleProfilefile} className="hidden" />
                        </div>
                        <div className='mt-3'>
                            <label htmlFor="first_name" className='text-xs mx-2'>
                                First Name
                                <span className="relative -bottom-2  text-red-600 text-2xl">*</span>
                            </label> <br />
                            <Field type="text" id="first_name" name="first_name" className={`h-10 px-2 text-sm   w-full border border-black  shadow-lg`} placeholder="Enter your first name" />
                            <ErrorMessage name="first_name" component="div" className=" text-red-500 font-medium text-xs m-2" />
                        </div>
                        <div className='mt-3'>
                            <label htmlFor="last_name" className='text-xs mx-2'>
                                Last Name
                                <span className="relative -bottom-2  text-red-600 text-2xl">*</span>
                            </label> <br />
                            <Field type="text" id="last_name" name="last_name" className={`h-10 px-2 text-sm   w-full border border-black  shadow-lg`} placeholder="Enter your last name" />
                            <ErrorMessage name="last_name" component="div" className=" text-red-500 font-medium text-xs m-2" />
                        </div>
                        <div className='mt-3'>
                            <label htmlFor="dob" className='text-xs mx-2'>
                                Date of Birth
                                <span className="relative -bottom-2  text-red-600 text-2xl">*</span>
                            </label> <br />
                            <Field type="date" id="dob" name="dob" className={`h-10 px-2 text-sm   w-full border border-black  shadow-lg`} />
                            <ErrorMessage name="dob" component="div" className=" text-red-500 font-medium text-xs m-2" />
                        </div>
                        <div className='mt-3'>
                            <label htmlFor="gender" className='text-xs mx-2'>
                                Gender
                                <span className="relative -bottom-2  text-red-600 text-2xl">*</span>
                            </label> <br />
                            <Field as="select" id="gender" name="gender" className={`h-10 px-2 text-sm   w-full border border-black  shadow-lg`}>
                                <option value="">Select Gender</option>
                                <option value="male">Male</option>
                                <option value="female">Female</option>
                                <option value="other">Other</option>
                            </Field>
                            <ErrorMessage name="gender" component="div" className=" text-red-500 font-medium text-xs m-2" />
                        </div>

                        <div className='mt-3'>
                            <label htmlFor="phone_number" className='text-xs mx-2'>
                                Phone Number
                                <span className="relative -bottom-2  text-red-600 text-2xl">*</span>
                            </label> <br />
                            <Field type="number" id="phone_number" name="phone_number" className={`h-10 px-2 text-sm   w-full border border-black  shadow-lg`} placeholder="Enter your phone number" />
                            <ErrorMessage name="phone_number" component="div" className=" text-red-500 font-medium text-xs m-2" />
                        </div>
                        <div className='mt-3'>
                            <label htmlFor="address" className='text-xs mx-2'>
                                Address
                                <span className="relative -bottom-2  text-red-600 text-2xl">*</span>
                            </label> <br />
                            <Field type="text" id="address" name="address" className={`h-10 px-2 text-sm   w-full border border-black  shadow-lg`} placeholder="Enter your address" />
                            <ErrorMessage name="address" component="div" className=" text-red-500 font-medium text-xs m-2" />
                        </div>
                        <div className='mt-3'>
                            <label htmlFor="zip_postal_code" className='text-xs mx-2'>
                                Zip/Post Code
                                <span className="relative -bottom-2  text-red-600 text-2xl">*</span>
                            </label> <br />
                            <Field type="number" id="zip_postal_code" name="zip_postal_code" className={`h-10 px-2 text-sm   w-full border border-black  shadow-lg`} placeholder="Enter your pincode" />
                            <ErrorMessage name="zip_postal_code" component="div" className=" text-red-500 font-medium text-xs m-2" />
                        </div>
                        <div className='mt-3'>
                            <label htmlFor="city" className='text-xs mx-2'>
                                City
                                <span className="relative -bottom-2  text-red-600 text-2xl">*</span>
                            </label> <br />
                            <Field type="text" id="city" name="city" className={`h-10 px-2 text-sm   w-full border border-black  shadow-lg`} placeholder="Enter your city" />
                            <ErrorMessage name="city" component="div" className=" text-red-500 font-medium text-xs m-2" />
                        </div>
                        <div className='mt-3'>
                            <label htmlFor="state" className='text-xs mx-2'>
                                State
                                <span className="relative -bottom-2  text-red-600 text-2xl">*</span>
                            </label> <br />
                            <Field type="text" id="state" name="state" className={`h-10 px-2 text-sm   w-full border border-black  shadow-lg`} placeholder="Enter your state" />
                            <ErrorMessage name="state" component="div" className=" text-red-500 font-medium text-xs m-2" />
                        </div>
                        <div className='mt-3 mb-8'>
                            <label htmlFor="country" className='text-xs mx-2'>
                                Country
                                <span className="relative -bottom-2  text-red-600 text-2xl">*</span>
                            </label> <br />
                            <Field type="text" id="country" name="country" className={`h-10 px-2 text-sm   w-full border border-black  shadow-lg`} placeholder="Enter your country" />
                            <ErrorMessage name="country" component="div" className=" text-red-500 font-medium text-xs m-2" />
                        </div>
                        <div>
                            {
                                status.isComplete && <p className={`font-medium text-center ${status.isSuccess ? "text-green-500" : "text-red-500"}`}>  {status.isSuccess ? "Successfully updated." : "Something went wrong."} </p>
                            }
                            {
                                profileError && <p className="text-red-500 font-medium text-center">Profile upload failed</p>
                            }
                        </div>
                        <div className='text-center flex w-full justify-evenly pb-10 pt-3'>
                            <button type="button" className=" bg-white text-black font-normal text-base w-full h-11 border border-black " onClick={() => { setProfile(true) }}> Cancel </button>
                            <button type="submit" className="bg-black text-white w-full  h-11 font-semibold text-base shadow-2xl ms-3" disabled={isLoading} >
                                {
                                    isLoading ? <div className="loader-container">
                                        <div className="loader"></div>
                                    </div> : <span> Update</span>
                                }
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>

        </div>
    )
}

export default Edit