import fairTeeth from "../../assets/illustrations/Fair-teeth.png"
import goodTeeth from "../../assets/illustrations/Good-teeth.png"
import poorTeeth from "../../assets/illustrations/Poor-teeth.png"
import download from "../../assets/Icons/download.png"
import { formattedDate } from "../../Utils/getFormattedDate"


const Report = (props) => {
    const { handlePdfDownload, data } = props
    const { createdAt, score, type, status, percentage, pdf_url } = data

    let image;
    let condition_text;
    let condition_text_color;

    //  judging scores according ml results

    if (score >= 75) {
        image = poorTeeth;
        condition_text = "Poor";
        condition_text_color = "text-red-600"
    } else if (score >= 50) {
        image = fairTeeth;
        condition_text = "Fair";
        condition_text_color = "text-orange-500"
    } else {
        image = goodTeeth;
        condition_text = "Good";
        condition_text_color = "text-green-600"
    }

    return (
        <div className="flex items-center border-b-2 border-gray-300 mx-4 pb-2 pt-4">
            <img src={image} alt="status" className="h-14 me-2" />
            <div className="flex-grow">
                <p className="mb-1 font-medium text-base">{formattedDate(createdAt)}</p>
                <p className="text-sm flex items-center">
                    <span className={` w-10 text-xs font-semibold ${condition_text_color} border-r border-gray-500 me-2`}> {condition_text} </span>

                    <span className=" text-xs opacity-90">
                        {
                            type === "initial" ? `Scored ${100 - score}%` :
                                percentage === 0 ? "Same as previous" : `${status} by ${percentage}%`

                        }
                    </span>
                </p>
            </div>
            <button
                type="button"
                className="rounded-full me-3"
                onClick={() => handlePdfDownload(pdf_url.split("/")[pdf_url.split("/").length - 1])}
            >
                <img src={download} alt="download" className="h-7" />

            </button>
        </div>
    )
}

export default Report