import TermsOfUse from "./termsOfUse";
import Disclaimer from "./disclaimer";
import Policy from "./policy";
const PrivacyPolicy = () => {
  return (
    <>
      <TermsOfUse />
      <Disclaimer />
      <Policy />
    </>
  );
};

export default PrivacyPolicy;
