import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, ErrorMessage, Field, Formik } from "formik"
import * as Yup from 'yup';
import eye from "../../../assets/Icons/eye.png"
import { resetPassword } from "../../../Services/APIs/authentication";
import BackButton from "../../BackButton";

const NewPassword = ({ email }) => {
    const { dentalOfficeName } = useParams();

    const navigate = useNavigate()
    const [info, setInfo] = useState()
    const [newPass, setNewPassVisible] = useState(false)
    const [confirmPass, setConfirmPassVisible] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [customError, setCustomError] = useState('')

    useEffect(() => {
        const data = sessionStorage.getItem("info")
        data ? setInfo(JSON.parse(data)) : navigate(`/${dentalOfficeName}`)

    }, [])


    const initialValues = {
        new_password: '',
        confirm_password: ''
    }

    const validateSchema = () => Yup.object().shape({
        new_password: Yup.string()
            .min(6, 'New password must be at least 6 characters')
            .required("New Password is required"),
        confirm_password: Yup.string().oneOf([Yup.ref('new_password'), null], 'Passwords must match').required("Confirm password is required.")
    })

    const handleSubmit = async (values) => {
        try {
            const data = { email: info.email, password: values.new_password }
            setLoading(true)
            setCustomError('')
            await resetPassword(data).then((response) => {
                setLoading(false)
                sessionStorage.removeItem("info")
                navigate(`/${dentalOfficeName}/login`, { replace: true })
            }).catch((error) => {
                setCustomError(error.response?.data?.message || 'An unexpected error occurred')
                setLoading(false)
            })
        } catch (err) {
            setCustomError('An unexpected error occurred')
            setLoading(false)
        }

    }

    return (
        <div className="h-full">
            <BackButton onClick={() => navigate(`/${dentalOfficeName}/forgot-password`)} />
            <Formik
                initialValues={initialValues}
                validationSchema={validateSchema}
                onSubmit={handleSubmit}
            >
                {() => (
                    <Form >
                        <div className="flex flex-col p-6 absolute top-1/4 ">
                            <h1 className="mx-1 font-normal text-lg text-center">Create New Password</h1>
                            <div className="flex-grow  bordher-4">
                                <div className='relative mt-5'>
                                    <label htmlFor="new_password" className='text-xs mx-2'>New Password</label>
                                    <Field type={`${newPass ? "text" : "password"}`} id="new_password" name="new_password" className={`h-10 px-2 text-sm mt-1 shadow-lg border border-black w-full`} placeholder="Enter new password" />
                                    <button type='button' className=' p-2  absolute right-1 top-8' onClick={() => { setNewPassVisible(prevState => !prevState) }}>
                                        <img src={eye} alt="eye" className='  h-3' />
                                    </button>
                                    <ErrorMessage name="new_password" component="div" className="text-red-500 font-medium text-xs m-2" />
                                </div>
                                <div className='relative mt-5'>
                                    <label htmlFor="confirm_password" className='text-xs mx-2'>Confirm New Password</label>
                                    <Field type={`${confirmPass ? "text" : "password"}`} id="confirm_password" name="confirm_password" className={`h-10 px-2 text-sm mt-1 shadow-lg border border-black w-full`} placeholder="Re-enter new password" />
                                    <button type='button' className=' p-2  absolute right-1 top-8' onClick={() => { setConfirmPassVisible(prevState => !prevState) }}>
                                        <img src={eye} alt="eye" className='  h-3' />
                                    </button>
                                    <ErrorMessage name="confirm_password" component="div" className="text-red-500 font-medium text-xs m-2" />
                                </div>
                            </div>
                            <div className='text-center mt-10'>
                                {customError && <p className="text-red-500 font-medium text-xs m-2"> {customError}</p>}
                                <button type="submit" className="bg-black text-white w-full  h-11 font-semibold text-base shadow-2xl" disabled={isLoading} >
                                    {
                                        isLoading ? <div className="loader-container">
                                            <div className="loader"></div>
                                        </div> : <span> Create Password</span>
                                    }
                                </button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>

    )

}

export default NewPassword