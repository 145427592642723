import close from "../../assets/illustrations/close.png"

const HealthPopup = (props) => {
    const { popupType, setPopup } = props
    const content = {
        Tooth: "Areas in the photos that appear like dental cavities",
        Gum: "Areas in the photos that appear like plaque, tartar, stains, swollen gums and gum recession",
        Alignment: "Areas in the photos that appear to have tooth alignment issues"

    }
    
    return (
        <div className="z-30 fixed h-full w-full top-0 left-0 bg-black/[0.5] flex justify-center items-center">
            <div className=" bg-white w-4/5 p-3">
                <div className="flex justify-between px-3 py-3 border-b-2">
                    <p className="text-base font-semibold">{popupType} Health Info</p>
                    <button className="" onClick={() => { setPopup(false) }}> <img src={close} className="w-6 h-6" alt="close" /></button>
                </div>
                <div className="p-3">
                    {content[popupType]}
                </div>
            </div>
        </div>
    )
}

export default HealthPopup