import { useSelector } from "react-redux"
import fairTeeth from "../../assets/illustrations/Fair-teeth.png"
import goodTeeth from "../../assets/illustrations/Good-teeth.png"
import poorTeeth from "../../assets/illustrations/Poor-teeth.png"
import caretUp from "../../assets/Icons/CaretUp.png"
import caretDown from "../../assets/Icons/CaretDown.png"

const News = () => {
    const teethHealth = useSelector((state) => state.teethHealth)

    const determineTeeth = () => {
        let imgSrc = goodTeeth
        if (teethHealth?.type === "comparison") {
            if (teethHealth?.overall_score.status === "Improved") {
                const score = teethHealth.overall_score.current_score
                if (score >= 50) {
                    imgSrc = goodTeeth
                } else if (score > 25) {
                    imgSrc = goodTeeth
                } else {
                    imgSrc = poorTeeth
                }
            } else { imgSrc = fairTeeth }

        } else if (teethHealth?.type === 'latest') {
            const score = teethHealth.overall_score.current_score
            if (score >= 50) {
                imgSrc = goodTeeth
            } else if (score > 25) {
                imgSrc = fairTeeth
            } else {
                imgSrc = poorTeeth
            }

        }

        return imgSrc
    }



    const Improved = () => <p className=" text-sm "> Great news! Your dental health <span className=" font-bold"> Improved by {teethHealth?.overall_score?.change}% </span> <img src={caretUp} className="h-4 mb-1 inline" alt="Caret Up" /> since the last scan! </p>
    const Declined = ({ isSame }) => <p className=" text-sm"> {isSame ? <span> Your dental health is the same as in your last scan </span> : <span> Your dental health has declined by {teethHealth.overall_score.change}% <img src={caretDown} className="h-4 mb-1 inline" alt="Caret Up" /> since the last scan! </span>}   </p>
    const Custom = ({ text }) => <p className=" text-sm">{text}  </p>

    const Phrase = () => {
        if (teethHealth.type === "comparison") {
            if (teethHealth.overall_score.status === "Improved") {
                const score = teethHealth.overall_score.current_score
                if (score >= 50) {
                    return <Improved />
                } else if (score > 25) {
                    return <Improved />
                } else {
                    let text = `Unfortunately, your dental health has declined by ${teethHealth.overall_score.change}% since the last scan`
                    return <Custom text={text} />

                }
            } else {
                return <Declined isSame={teethHealth.overall_score.change === 0} />
            }
        } else if (teethHealth?.type === 'latest') {
            const score = teethHealth.overall_score.current_score
            let text = ''
            if (score >= 50) {
                text = "Your teeth look great! Rescan in a month to stay on top of your dental health"
                return <Custom text={text} />
            } else if (score > 25) {
                text = "Some areas need attention. Consult a dentist for advice"
                return <Custom text={text} />
            } else {
                text = "Some areas need attention. Consult a dentist for advice"
                return <Custom text={text} />
            }

        }

        return null
    }



    return (
        <div className="flex items-center border bg-gradient-to-r from-white  to-gray-300 mx-6 mt-3 py-1 rounded-full">
            <img src={determineTeeth()} alt="status" className="h-14 mx-2" />
            <Phrase />
        </div >
    )
}

export default News