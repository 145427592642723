import React, { useEffect, useState } from "react";
import "./index.css";
import { useSelector } from "react-redux";
import { formattedDate } from "../../Utils/getFormattedDate";

const NewGraph = () => {

    const teethHealth = useSelector(state => state.teethHealth)
    const [title, setTitle] = useState()
    const [description, setDescription] = useState('')
    const [width, setWidth] = useState(220)

    const determineStatus = (teethHealth) => {

        if (teethHealth.type === "comparison") {
            if (teethHealth.overall_score.status === "Improved") {
                const score = teethHealth.overall_score.current_score
                if (score >= 50) {
                    setDescription(`Congrats! You've improved by ${teethHealth.overall_score.change}% since the last scan`)

                } else if (score > 25) {
                    setDescription(`Congrats! You've improved by ${teethHealth.overall_score.change}% since the last scan`)

                } else {
                    setDescription(`Congrats! You've improved by ${teethHealth.overall_score.change}% since the last scan`)

                }
            } else {
                const text = teethHealth.overall_score.change === 0 ? "Your dental health is the same as in your last scan" : `Your dental health has declined by ${teethHealth.overall_score.change}% since the last scan`
                setDescription(text)
            }
        } else if (teethHealth.type === 'latest') {
            const score = teethHealth.overall_score.current_score
            if (score >= 50) {
                setDescription("Your teeth seem to be in good shape!")
            } else if (score > 25) {
                setDescription("Hey, it looks like there are some dental issues")
            } else {
                setDescription("Hey, it looks like there are some dental issues")

            }

        }


    }

    useEffect(() => {
        if (teethHealth?.overall_score) {
            const score = teethHealth.overall_score.current_score
            determineStatus(teethHealth)

            let bgColor = ''
            if (score >= 50) {
                bgColor = 'bg-green-500'
                setTitle("Good")
            } else if (score > 25) {
                bgColor = "bg-orange-400"
                setTitle("Fair")
            } else {
                bgColor = "bg-red-500"
                setTitle("Poor")
            }
            const linesContainer = document.getElementById('linesContainer');
            setWidth(linesContainer.clientWidth)
            linesContainer.innerHTML = '';

            const totalLines = 70;
            const startAngle = 200;
            // const endAngle = 160;
            const skipAngleStart = 165;
            const skipAngleEnd = 200;
            const skipAngleRange = skipAngleEnd - skipAngleStart;
            const availableAngleRange = 360 - skipAngleRange;
            const greenLines = Math.round((score / 100) * totalLines);
            let currentGreenLines = 0;



            const createLine = (i) => {
                let rotationAngle = (availableAngleRange / totalLines) * i + startAngle;
                if (rotationAngle >= 360) {
                    rotationAngle -= 360;
                }
                if (rotationAngle >= skipAngleStart && rotationAngle < skipAngleEnd) {
                    rotationAngle += skipAngleRange;
                    if (rotationAngle >= 360) {
                        rotationAngle -= 360;
                    }
                }

                const line = document.createElement('div');
                line.classList.add('absolute', 'left-2/4', 'w-0.5', 'h-3', 'transform', 'line');
                line.style.transform = `rotate(${rotationAngle}deg)`;
                line.style.transformOrigin = `50% ${linesContainer.clientWidth / 2}px`; // Dynamically set transform origin


                if (currentGreenLines < greenLines) {
                    if (currentGreenLines === greenLines - 1) {
                        line.classList.add(bgColor);
                        line.classList.add('bigger'); //  issue here


                    } else {
                        line.classList.add(bgColor);
                    }
                    currentGreenLines++;
                } else {
                    line.classList.add('bg-slate-300');
                }

                linesContainer.appendChild(line);
            }

            for (let i = 0; i < totalLines; i++) {
                // createLine(i)
                setTimeout(() => createLine(i), i * 15); // enable this for animation.
            }
        }

    }, [teethHealth]);

    return (
        <div className="flex flex-col items-center flex-grow  px-4">
            <div className="h-full w-full flex flex-col flex-grow justify-center items-center relative">
                <div id="linesContainer" className="w-full" style={{ height: `${width}px` }} >             </div>
                {teethHealth &&
                    <>
                        <div className="shadow-custom text-center absolute rounded-full flex flex-col justify-center items-center bg-gradient-to-br from-white  to-gray-200" style={{ width: `${width / 100 * 75}px`, height: `${width / 100 * 75}px` }} >
                            <p className=" text-39 font-medium"> {title} </p>
                            <p className="px-5 w-4/5"> {description} </p>
                        </div >
                        <p className=" text-base font-medium -mt-5"> {formattedDate(teethHealth.date)}  </p>
                    </>
                }

            </div>
        </div>
    );
};

export default React.memo(NewGraph);


