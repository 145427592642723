import React, { useEffect, useRef, useState } from "react";
import ImagePlaceholder from "./image-placeholder1.svg";
import "./annotation.css";

const Annotation = (props) => {
  let imageTagsData = [];
  const [activeImage, setActiveImage] = useState(props?.image?.imageUrl);
  const imgRef = useRef();
  const [filteredImageTags, setFilteredImageTags] = useState([]);

  useEffect(() => {
    setActiveImage(props?.image?.imageUrl)
    if (!props.texTags && props.jsonTags) {
      jsonTags()
    } else if (props.texTags) {
      getTxtTags()
    }
  }, [props])

  const jsonTags = () => {
    let tagsData = props.jsonTags
    const GetShortName = (name, splitOption) => {
      return (
        name &&
        `${name
          .split(splitOption)
          .map((item) => item[0].toUpperCase())
          .join("")}`
      );
    };
    const TAGS_COLOR_CODE = {
      C: "#FF0000",
      C1: "#FF0000",
      C2: "#FF0000",
      C3: "#FF0000",
      T: "#0000FF",
      E: "#298542",
      GS: "#68a8a8",
      GR: "#FFA500",
      BT: "#EE82EE",
    };
    const imageTags = [];
    const imgNaturalWidth = imgRef.current.naturalWidth;
    const imgNaturalHeight = imgRef.current.naturalHeight;
    const renderImageWidth = imgRef.current.width;
    const renderImageHeight = imgRef.current.height;
    const coordinatesData = [];
    for (let key in tagsData) {
      if (Array.isArray(tagsData[key]) && key !== "missing_teeth") {
        tagsData[key].forEach((item) => {
          let { x, y, w, h } = item;
          x = (x * renderImageWidth) / imgNaturalWidth;
          y = (y * renderImageHeight) / imgNaturalHeight;
          w = (w * renderImageWidth) / imgNaturalWidth;
          h = (h * renderImageHeight) / imgNaturalHeight;
          coordinatesData.push({ x, y, width: w, height: h });
          const shortName = GetShortName(key, "_");
          const colorCode = TAGS_COLOR_CODE[shortName];
          imageTags.push({
            name: shortName,
            style: {
              left: `${x}px`,
              top: `${y}px`,
              width: `${w}px`,
              height: `${h}px`,
              border: `3px solid ${colorCode}`,
              background: "transparent",
            },
            nameStyle: {
              position: "relative",
              top: "-2px",
              left: "0",
              background: colorCode,
              color: colorCode,
            },
          });
        });
      }
    }
    setFilteredImageTags(imageTags)

  }

  const getTxtTags = () => {
    if (props.texTags != null) {
      const imageTags = []
      let coordinates = [];
      const imgWidth = imgRef?.current?.width;
      const imgHeight = imgRef?.current?.height;
      let tartar = 0;
      let cavity = 0;
      const cavityCall = () => {
        return (cavity = cavity + 1);
      };
      const tartarCall = () => {
        return (tartar = tartar + 1);
      };
      const tagsData = props.texTags;
      tagsData.forEach((data, index) => {
        const splitCoordinates = data.split(" ").slice(1, 5);
        if (splitCoordinates.length > 1) {
          const { x, y, w, h } = {
            x: Number(splitCoordinates[0]),
            y: Number(splitCoordinates[1]),
            w: Number(splitCoordinates[2]),
            h: Number(splitCoordinates[3]),
          };
          const { x_min, x_max, y_min, y_max } = getTagsCoordinates(
            { x, y, w, h },
            imgWidth,
            imgHeight
          );
          coordinates.push({
            x: x_min,
            y: y_min,
            width: x_max - x_min,
            height: y_max - y_min,
          });
          const colorCode = data.split(" ")[0] == 0 ? "#0000FF" : data.split(" ")[0] == 1 ? "#FF0000" : data.split(" ")[0] == 2 ? "#68a8a8" : data.split(" ")[0] == 3 ? "#68a8a8" : "#0000FF"
          data.split(" ")[0] ? tartarCall() : cavityCall();
          imageTags.push({
            name: data.split(" ")[0] ? "T" : "C",
            style: {
              left: `${x_min}px`,
              top: `${y_min}px`,
              width: `${x_max - x_min}px`,
              height: `${y_max - y_min}px`,
              border: `3px solid ${colorCode}`,
              background: "transparent",
            },
            nameStyle: {
              position: "relative",
              top: "-3px",
              left: "0",
              background: colorCode,
              color: colorCode,
            },
          });
        }
      });
      setFilteredImageTags(imageTags)
      imageTagsData = coordinates;

      function getTagsCoordinates({ x, y, w, h }, imgWidth, imgHeight) {
        let x_min = (x - w / 2) * imgWidth;
        let x_max = (x + w / 2) * imgWidth;
        let y_min = (y - h / 2) * imgHeight;
        let y_max = (y + h / 2) * imgHeight;

        return {
          x_min,
          x_max,
          y_min,
          y_max,
        };
      }

    }
  }

  return (
    <div className="position-relative " id="drawContainer">
      <img
        className=" h-80 rounded"
        src={activeImage ? activeImage : ImagePlaceholder}
        id="screenshot"
        alt="report"
        draggable="false"
        ref={imgRef}
      />
      {filteredImageTags.map((item, index) => {
        return (
          <div
            key={index}
            className="popup-annotation tag-name border-0"
            style={item.style}
          >
            <div
              className="d-inline-flex ps-1 font-size-14 popup-header"
              style={item.nameStyle}
            >
            </div>
          </div>
        );
      })}
    </div>
  );
}
export default Annotation;
