import React from "react"
import { Navigate, useParams } from "react-router-dom"

const PrivateRoute = ({Component}) => {
    const { dentalOfficeName } = useParams();
    const isAuthenticated = localStorage.getItem('user') && dentalOfficeName === localStorage.getItem("dentalOffice")


    if (isAuthenticated) {
        return Component
    } else {
        localStorage.clear()
        return <Navigate to={`/${dentalOfficeName}/login`} />

    }


}

export default PrivateRoute