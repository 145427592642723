import React from "react";
import CryptoJS from 'crypto-js';
import { useNavigate, useParams } from "react-router-dom"
import { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import teeth from "../../../assets/illustrations/LoginTeeth.png"
import eye from "../../../assets/Icons//eye.png"

import "../../../App.css"
import TermsAndConditions from "../../TermsAndConds";
import { registerUser } from "../../../Services/APIs/authentication";
import BackButton from "../../BackButton";

const SignIn = (props) => {
    const { dentalOfficeName } = useParams();

    const navigate = useNavigate()
    const [isLoading, setLoading] = useState(false)
    const [isEmailExist, setEmailExist] = useState(false)
    const [isPasswordVisible, setPasswordVisible] = useState(false)
    const [termsandConds, setTermsAndConditions] = useState(false)
    const [customError, setCustomError] = useState('')


    const validateSchema = Yup.object().shape({
        name: Yup.string().required("Name is required"),
        email: Yup.string().email('Invalid email')
            .matches(/\.[a-zA-Z]{2,}$/, 'Invalid email')
            .required('Email is required'),
        password: Yup.string()
            .min(6, 'Password must be at least 6 characters')
            .required('Password is required'),
        termsConds: Yup.boolean()
            .oneOf([true], 'Please accept the terms and conditions')
            .required("Please accept the terms and conditions")
    })

    const initialValues = {
        name: '',
        email: '',
        password: '',
        termsConds: false
    };

    const signUpUser = async values => {
        setCustomError('')
        try {
            const secret_key = process.env.REACT_APP_SIGN_UP_ENCRYPTION_KEY
            const encrypted = CryptoJS.AES.encrypt(JSON.stringify(values), secret_key).toString()
            setLoading(true)
            setEmailExist(false)
            await registerUser({ info: encrypted }).then((response) => {
                setLoading(false)
                sessionStorage.setItem("info", JSON.stringify({ email: values.email, new_sign_in: true }))
                navigate(`/${dentalOfficeName}/otp`)
            }).catch((error) => {
                setLoading(false)
                if (error.response?.status === 406) {
                    setEmailExist(true)
                } else {
                    setCustomError(error.response?.data?.message || 'An unexpected error occurred')
                }
            })

        } catch (err) {
            setCustomError('An unexpected error occurred')
        }

    }

    return (
        <div className="h-full">
            <BackButton onClick={() => { navigate(`/${dentalOfficeName}`) }} />
            <img src={teeth} alt="teeth" className='saturation absolute top-0 right-0 h-48' />
            <div className="  mx-6 flex flex-col justify-evenly sign-up-form-container ">
                <h1 className="mx-1 font-normal text-39">Hi!</h1>
                <p className="mt-4 mx-1 font-medium text-base text-black opacity-60">Create a new account</p>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validateSchema}
                    onSubmit={signUpUser}
                >

                    {({ isSubmitting, errors, touched }) => (
                        <Form>
                            <div className='mt-5'>
                                <label htmlFor="name" className='text-xs mx-2'>Name</label> <br />
                                <Field type="text" id="name" name="name" className={`h-10 px-2 text-sm mt-1 w-full border border-black  shadow-lg`} placeholder="Enter your name" />
                                <ErrorMessage name="name" component="div" className=" text-red-500 font-medium text-xs m-2" />
                            </div>
                            <div className='mt-5'>
                                <label htmlFor="email" className='text-xs mx-2'>Email ID</label> <br />
                                <Field type="email" id="email" name="email" className={`h-10 px-2 text-sm mt-1 w-full border ${isEmailExist && "border-red-500"} border-black  shadow-lg `} placeholder="Enter your email" />
                                <ErrorMessage name="email" component="div" className=" text-red-500 font-medium text-xs m-2" />
                                {isEmailExist && !errors.email && <p className='text-red-500 font-medium text-xs m-2'>This email is already registered. </p>}
                            </div>
                            <div className='relative mt-5'>
                                <label htmlFor="password" className='text-xs mx-2'>Password</label>
                                <Field type={`${isPasswordVisible ? "text" : "password"}`} id="password" name="password" className={`h-10 px-2 text-sm mt-1 shadow-lg border border-black w-full`} placeholder="Enter password" />
                                <button type='button' className=' p-2  absolute right-1 top-8' onClick={() => { setPasswordVisible(prevState => !prevState) }}>
                                    <img src={eye} alt="eye" className='  h-3' />
                                </button>
                                <ErrorMessage name="password" component="div" className="text-red-500 font-medium text-xs m-2" />
                            </div>
                            <div className='mt-6'>
                                <div className="flex">
                                    <Field type="checkbox" id="termsConds" name="termsConds" className={`h-5 w-5 border-2 rounded-md shadow-lg`} />
                                    <button type="button" className='text-xs ms-2' onClick={() => { setTermsAndConditions(true) }}> I agree to the <span className=" font-bold" > Terms & Conditions, Privacy Policy & Disclaimer. </span> </button>
                                </div>
                                <ErrorMessage name="termsConds" component="div" className=" text-red-500 font-medium text-xs m-2" />
                            </div>
                            <div className='text-center  mt-14'>
                                {customError && <p className="text-red-500 font-medium text-xs m-2"> {customError}</p>}
                                <button type="submit" className="bg-black text-white w-full  h-11 font-semibold text-base shadow-2xl" disabled={isLoading} >
                                    {
                                        isLoading ? <div className="loader-container">
                                            <div className="loader"></div>
                                        </div> : <span> Sign Up</span>
                                    }
                                </button>
                            </div>
                            <div className='text-center m-4'>
                                <button type="button" onClick={() => { navigate(`/${dentalOfficeName}/forgot-password`) }}> Forgot Password? </button>
                            </div>
                        </Form>

                    )}
                </Formik>

            </div>
            <div className="w-screen pt-3 pb-6">
                <p className="text-center text-black ">
                    <span className="opacity-60"> Already have an account</span>
                    <button className=" font-bold  pl-2" onClick={() => navigate(`/${dentalOfficeName}/login`)}> Log In </button>
                </p>
            </div>
            {termsandConds && <TermsAndConditions back={setTermsAndConditions} />}
        </div>
    )
}

export default SignIn