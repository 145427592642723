import React from "react"
import { Navigate, useParams } from "react-router-dom"

const PublicRoute = ({Component}) => {
    const { dentalOfficeName } = useParams();
    const isAuthenticated = localStorage.getItem('user') 
    
    return isAuthenticated ? <Navigate to={`/${dentalOfficeName}/dashboard`} /> : Component
}

export default PublicRoute