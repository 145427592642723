import React from "react";

const Disclaimer = () => {
  return (
    <div className="px-4">
      <h1 className="font-bold text-center my-3 text-xl">DISCLAIMER</h1>
      <div className="my-3">
        The Smartcheck report provided here is generated by an artificial
        intelligence system, which is based purely on the quality of images
        submitted by you. Therefore, certain issues may not be visible through
        images alone.
      </div>
      <div className="my-3">
        The system being used here does not guarantee any accuracy. It is meant
        only for ease of use and simplicity in analysis. For an accurate and
        detailed information about your oral health, you are requested to have
        this report verified by a qualified dental professional and seek
        appropriate medical advice accordingly.
      </div>
      <div className="my-3">
        This report is intended solely for informational purposes. It does not
        substitute a professional dental checkup and/or advice by a qualified
        dental professional.
      </div>
    </div>
  );
};

export default Disclaimer;
