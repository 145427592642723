import { Navigate, useParams } from "react-router-dom"

const offices = ["edental", "Toothlens-Inc", "Banning-dental","Benepower"]

const Validate = ({ Component }) => {
    const { dentalOfficeName } = useParams();
    
    if (offices.includes(dentalOfficeName)) {
        return Component
    } else {
        localStorage.clear()
        return <Navigate to="/Toothlens-Inc" />
    }

}

export default Validate


