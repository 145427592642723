import { dentalOfficeName } from "../../Utils/AppDetails/appDetails"
import { API_HOST } from "../BaseUrl"
import client, { headers } from "./config"

export const GetBucketFile = async (fileData) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await client.post(API_HOST +'review-report/get-file', fileData,  { headers })
            if (response.status === 200) {
                resolve(response)
            }
            reject(response)
        } catch (error) {
            reject(error)
        }

    })

}


export const annotatedNotReviewedReports = async (filters) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await client.post(API_HOST +'annotatedAndNotReviewedReports', 
                {
                    params: filters,
                    headers
                },
              { headers })
            if (response.status === 200) {
                resolve(response)
            }
            reject(response)
        } catch (error) {
            reject(error)
        }

    })

}


export const getJsonTagDetails = async (imageName) => {
    return new Promise(async (resolve, reject) => {
        try {
            const response = await client.get(API_HOST +'json-tags-data', 
                {
                    params: {imageName},
                    headers
                },
              { headers })
            if (response.status === 200) {
                resolve(response)
            }
            reject(response)
        } catch (error) {
            console.log(error)
            reject(error)
        }

    })

}


export const postBookAppointment = async () => {
    return new Promise(async (resolve, reject) => {
        try {
            const mlResults=JSON.parse(localStorage.getItem("tpscore"))
            const payload={
            tp_uid:JSON.parse(localStorage.getItem("usertpi")).usertpi,
            gaps:mlResults.gaps,
            overall_score:mlResults.overall_score,
            tartar_score:mlResults.tartar_score,
            gum_recession:mlResults.gum_recession,
            gum_swelling:mlResults.gum_swelling,
            cavity_score:mlResults.cavity_score
            } 
            const response = await client.post(API_HOST +'bearn-book-appointment', 
              payload)
            if (response.status === 200) {
                resolve(response)
            }
            reject(response)
        } catch (error) {
            console.log(error)
            reject(error)
        }

    })

}