import React, { useState, useEffect } from 'react';
import './index.css';
import "../TermsAndConds/index.css"
import News from './news';
import Progress from './progress';
import Info from './info';
import { downloadPdf } from '../../Services/APIs/report';
import { useSelector } from 'react-redux';

const ScoreCard = (props) => {
    const { isOpen, setIsOpen } = props
    const [startY, setStartY] = useState(null);
    const [DownloadStatus, setDownloadStatus] = useState('Download')
    const teethHealth = useSelector((state) => state.teethHealth)
    useEffect(() => {
        if (localStorage.getItem("scoreCard")) {
            setTimeout(() => {
                setIsOpen(true)
            }, 200)
        }

    }, [])

    const handleTouchStart = (e) => {
        setStartY(e.touches[0].clientY);
    };

    const handleTouchMove = (e) => {
        if (!startY) return;

        const currentY = e.touches[0].clientY;
        const diffY = startY - currentY;

        if (diffY > 0 && teethHealth) {
            // Swiping down
            setIsOpen(true);
        } else {

            // Swiping up
            setIsOpen(false);
            localStorage.removeItem("scoreCard")
        }

        setStartY(null);
    };

    useEffect(() => {
        window.addEventListener('touchstart', handleTouchStart);
        window.addEventListener('touchmove', handleTouchMove);
        return () => {
            window.removeEventListener('touchstart', handleTouchStart);
            window.removeEventListener('touchmove', handleTouchMove);
        };
    }, [startY]);

    const download = async () => {
        setDownloadStatus('Downloading...')
        const file = localStorage.getItem("pdf")
        if (file) {
            await downloadPdf({ file }).then((response) => {
                const url = response.data?.data?.info
                const link = document.createElement("a");
                link.href = url;
                link.download = file;  // Optional: Specify a download attribute
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setDownloadStatus("Downloaded")
                setTimeout(() => {
                    setDownloadStatus("Download")
                }, 2500)

            }).catch((error) => {
                console.log("error", error)
                setDownloadStatus("Failed, try again...")
                setTimeout(() => {
                    setDownloadStatus("Download")
                }, 2500)
            })

        } else {
            setDownloadStatus("Not Found")
        }


    }

    return (
        <div className={`popup ${isOpen ? 'open' : 'closed'} bg-black/[0.3] backdrop-blur-sm flex items-end`}>
            <div className='h-4/5 bg-white flex flex-col w-full'>
                <div className="flex justify-center">
                    <p className="dragger rounded my-1"></p>
                </div>
                <div className='flex-grow overflow-auto '>
                    <News />
                    <Progress />
                    <Info />
                    <div className=' fixed bottom-0   w-full p-5  bg-gray-300/[0.1] backdrop-blur-xs rounded '>
                        {
                            DownloadStatus === "Downloaded" ?
                                <p className='py-3 bg-green-100 rounded text-center font-semibold text-green-900'>Downloaded successfully</p> :
                                <button type="button" className="bg-black text-white w-full h-11 font-semibold text-base shadow-2xl" onClick={download}> {DownloadStatus}  </button>
                        }
                    </div>
                    <div className='h-28 '></div>
                </div>
            </div>
        </div>
    );
};

export default ScoreCard;





















