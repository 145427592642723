import React from "react";
import fairTeeth from "../../assets/illustrations/Fair-teeth.png";
import goodTeeth from "../../assets/illustrations/Good-teeth.png";
import poorTeeth from "../../assets/illustrations/Poor-teeth.png";
import {
  fontStyle,
  buttonStyle,
  fontWeight,
  companyBucket,
} from "../../Utils/tpscanStyle";
const BookAppointment = () => {
  const score = 100 - JSON.parse(localStorage.getItem("tpscore")).overall_score;

  const determineTeeth = () => {
    let imgSrc = goodTeeth;
    if (score >= 50) {
      imgSrc = goodTeeth;
    } else if (score > 25) {
      imgSrc = fairTeeth;
    } else {
      imgSrc = poorTeeth;
    }
    return imgSrc;
  };

  const Custom = ({ text }) => (
    <p className=" text-sm" style={{ ...fontWeight, ...fontStyle }}>
      {text}{" "}
    </p>
  );

  const Phrase = () => {
    let text = "";
    if (score >= 50) {
      text =
        "Great job on completing your dental scan! To stay on top of your dental health, remember to scan in a month.";
      return <Custom text={text} />;
    } else if (score > 25) {
      text = "Great job on completing your dental scan! Some areas need attention. Consult a dentist for advice.";
      return <Custom text={text} />;
    } else {
      text = "Great job on completing your dental scan! Some areas need attention. Consult a dentist for advice.";
      return <Custom text={text} />;
    }
  };

  return (
    <div className="border  mx-6 mt-4 p-3">
      <div className="flex items-center ">
        <img src={determineTeeth()} alt="status" className="h-14 mx-2" />
        <Phrase />
      </div>
      {JSON.parse(localStorage.getItem("company_style_details"))
        .load_appointment_booking && companyBucket == "ybrush" && (
        <div className="flex items-center pt-3">
          <button
            type="button"
            className="bg-black text-white w-full h-11 font-semibold text-base"
            style={{ ...buttonStyle, ...fontStyle, ...fontWeight }}
          >
              <a
                href="https://y-brush.com/en"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Visit Y-Brush website
              </a>
            
            
          </button>
        </div>
      )}
    </div>
  );
};

export default BookAppointment;
