import { useState } from "react";
import fairStatus from "../../assets/illustrations/State=Fair.png";
import goodStatus from "../../assets/illustrations/State=Good.png";
import poorstatus from "../../assets/illustrations/State=Poor.png";
import infoIcon from "../../assets/Icons/info.png";
import HealthPopup from "../ScoreCard/healthPopup";
import { fontStyle,fontWeight,buttonStyle } from "../../Utils/tpscanStyle";
const TpInfo = () => {
  const teethHealth = JSON.parse(localStorage.getItem("tpscore"));

  const status = [
    { type: "tooth_health", title: "Tooth", img: goodStatus },
    { type: "gum_health", title: "Gum", img: fairStatus },
    { type: "alignment_health", title: "Alignment", img: poorstatus },
  ];
  let gum_health = [
    Math.min(teethHealth.tartar_score, 100),
    Math.min(teethHealth.gum_recession, 100),
    Math.min(teethHealth.gum_swelling, 100),
  ];
  const gum_health_score =100-gum_health.reduce((i, score) => score + i, 0) / 3;
  const tooth_health = 100 - Math.min(teethHealth.cavity_score, 100);
  const [popup, setPopup] = useState(false);
  const [popupType, setPopupType] = useState();

  const openPopup = (data) => {
    setPopupType(data);
    setPopup(true);
  };

  const determineGumStatus = (score) => {
    let imgSrc = "";
    if (score <= 25) {
      imgSrc =  poorstatus;
    } else if (score > 25 && score<50) {
      imgSrc = fairStatus;
    } else {
      imgSrc = goodStatus;
    }
    return imgSrc;
  };

  const determineCavity = (score) => {
    let cavityCalc=100-score
    let imgSrc = "";
    if (cavityCalc === 100) {
      imgSrc = goodStatus;
    } else if (cavityCalc>=25 && cavityCalc<= 99) {
      imgSrc = fairStatus;
    } else {
      imgSrc = poorstatus;
    } 
    return imgSrc;
  };

  const Health = (props) => {
    const { data } = props;
    let text = "";
    let imgSrc = "";
    if (data.type === "alignment_health") {
      text = teethHealth.gaps ? "Found gaps" : "Found no gaps";
      imgSrc = teethHealth.gaps ? fairStatus : goodStatus;
    } else if (data.type === "gum_health") {
      text = `Scored ${Math.round(gum_health_score)}% `;
      imgSrc = determineGumStatus(gum_health_score);
    } else {
      text = `Scored ${tooth_health}% `;
      imgSrc = determineCavity(teethHealth.cavity_score);
    }

    return (
      <div className="  mx-5 flex justify-start items-center">
        <div className="my-1 ms-2 w-36 ">
          <p className="my-1 text-base font-medium" style={{...fontWeight,...fontStyle}}>{data.title} Health </p>
        </div>
        <div className="flex flex-col flex-grow">
          <button
            type="button"
            className="mb-1"
            onClick={() => {
              openPopup(data.title);
            }}
          >
            <img src={infoIcon} alt="info" className="h-3" />
          </button>
        </div>
        {imgSrc && <img src={imgSrc} alt="status" className="h-11 me-2 mt-2" />}
      </div>
    );
  };

  return (
    <>
      {status.map((eachStatus, i) => (<Health key={i} data={eachStatus} /> ))}
      {popup && ( <HealthPopup popupType={popupType} data={"data"} setPopup={setPopup} /> )}
    </>
  );
};

export default TpInfo;
