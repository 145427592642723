import { useSelector } from "react-redux"
import BackButton from "../BackButton"

const Gallery = () => {
    const { images } = useSelector((state) => state.fullScan)

    return (
        <div className="h-full">
            <div className="flex items-center">
                <BackButton onClick={() => { window.history.back() }} />
                <p className="font-medium ms-2 text-xl">Gallery</p>
            </div>
            <div className="flex flex-wrap mt-5 mx-3">
                {
                    images.length > 0 ?
                        images.map((eachObj, i) => <img key={i} src={eachObj.dataUri} alt="scan" className=" m-1 h-16 rounded" />) :
                        <p className="mt-10 w-full text-center">No images Found.</p>
                }
            </div>

        </div>
    )
}

export default Gallery