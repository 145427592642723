import React, { useState } from "react"
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import { useNavigate, useParams } from "react-router-dom"
import { forgotPassword } from "../../../Services/APIs/authentication";
import BackButton from "../../BackButton";

const ForgotPassword = (props) => {
    const { dentalOfficeName } = useParams();

    const navigate = useNavigate()
    const [isLoading, setLoading] = useState(false)
    const [emailNotFound, setEmailNotFound] = useState(false)
    const [customError, setCustomError] = useState('')



    const validateSchema = Yup.object().shape({
        email: Yup.string().email('Invalid email')
            .matches(/\.[a-zA-Z]{2,}$/, 'Invalid email')
            .required('Email is required'),
    })

    const initialValues = {
        email: '',
    };

    const sendOtp = async values => {
        setLoading(true)
        setEmailNotFound(false)
        setCustomError('')
        await forgotPassword(values).then((response) => {
            setLoading(false)
            sessionStorage.setItem("info", JSON.stringify({ email: values.email, new_sign_in: false }))
            navigate(`/${dentalOfficeName}/otp`)
        }).catch((error) => {
            setLoading(false)
            if (error.response?.status === 406) {
                setEmailNotFound(true)
            } else {
                setCustomError(error.response?.data?.message || 'An unexpected error occurred')
            }
        })


    }

    return (
        <div className="h-full">
            <BackButton onClick={() => window.history.back()} />
            <div className="mx-6 flex flex-col justify-center  h-2/3">
                <h1 className="font-normal text-39 mx-1 leading-9"> Forgot </h1>
                <h1 className="font-normal text-39 mx-1"> Password </h1>
                <p className="mt-3 mx-1 font-medium text-base opacity-60">Enter your registered email ID</p>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validateSchema}
                    onSubmit={sendOtp}
                >
                    {({ isSubmitting, errors, touched }) => (
                        <Form>
                            <div className='mt-7'>
                                <label htmlFor="email" className='text-xs mx-2'>Email ID</label> <br />
                                <Field type="email" name="email" className={`h-10 px-2 text-sm w-full border ${emailNotFound && "border-red-500"} mt-1 border-black  shadow-lg `} placeholder="Enter your email" />
                                <ErrorMessage name="email" component="div" className=" text-red-500 font-medium text-xs m-2" />
                                {emailNotFound && !errors.email && <p className='text-red-500 font-medium text-xs m-2'>This email is not registered. </p>}
                            </div>
                            <div className='text-center mt-10'>
                                {customError && <p className="text-red-500 font-medium text-xs m-2"> {customError}</p>}
                                <button type="submit" className="bg-black text-white w-full h-11 font-semibold text-base  shadow-2xl" disabled={isLoading} >
                                    {
                                        isLoading ? <div className="loader-container">
                                            <div className="loader"></div>
                                        </div> : <span>Send Verification Code</span>
                                    }
                                </button>
                            </div>
                        </Form>
                    )}

                </Formik>
            </div>
            <div className="absolute bottom-6 w-screen">
                <p className="text-center text-black ">
                    <span className="opacity-60"> Don’t have a account</span>
                    <button className="font-bold pl-2" onClick={() => navigate(`/${dentalOfficeName}/sign-in`)}>  Sign In </button>
                </p>
            </div>
        </div>
    )
}

export default ForgotPassword