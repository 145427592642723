import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import toothlensLogo from "../../assets/illustrations/Toothlens_logo.png"
import activeNotification from "../../assets/Icons/Active.png"
import menu from "../../assets/Icons/Menu.png"
import profileLogo from "../../assets/illustrations/profile_logo.png"
import infoIcon from "../../assets/Icons/info.png"
import ScoreCard from "../ScoreCard"
import NewGraph from "../ScoreCard/newGraph"
import { getScore } from "../../Services/APIs/report"
import { useDispatch, useSelector } from "react-redux"
import { storeHealth } from "../../Store/teethHealth"
import LookingFor from "../LookingFor"
import { checkAppVersion } from "../../Services/APIs/appDetails"
import { getProfileLogo, noProfile } from "../../Store/profile"


const Dashboard = () => {
    const { dentalOfficeName } = useParams();

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [isLoading, setLoading] = useState(true)
    const [isError, setError] = useState(false)
    const [isScoreCardOpen, SetScoreCard] = useState(false)
    const [reason, setReason] = useState(false)
    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).user : {}
    const teethHealth = useSelector(state => state.teethHealth)
    const { imageUrl, isProfileExist, isProfileLoading } = useSelector((state) => state.profileLogo)


    useEffect(() => {
        if (user?.profile) {
            !imageUrl && dispatch(getProfileLogo({ profile: user.profile }))
        } else {
            dispatch(noProfile())
        }

    }, [])


    const updateAppVersion = async () => {
        const version = localStorage.getItem("version")
        await checkAppVersion().then((res) => {
            if (res.data.data !== "No Data") {
                const newVersion = res.data.data.version
                if (!version || newVersion !== version) {
                    localStorage.setItem("version", newVersion)
                    window.location.reload()
                }
            }
        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        updateAppVersion()
        if (!teethHealth) {
            setLoading(true)
            setError(false)
            getScore().then((res) => {
                if (res.data.data !== "No Data") {
                    dispatch(storeHealth(res.data.data))
                    const pdf_url= res.data.data.pdf_url
                    const pdf = pdf_url.split("/")[pdf_url.split("/").length - 1]
                    localStorage.getItem("scoreCard") && SetScoreCard(true)
                    localStorage.setItem("pdf", pdf )

                } else {
                    dispatch(storeHealth(''))
                }
                setLoading(false)

            }).catch((error) => {
                dispatch(storeHealth(''))
                setError(true)
                setLoading(false)
                console.log(error)
            })
        } else {
            setLoading(false)
        }

    }, [])



    const Header = () => {
        return (
            <div className="flex justify-between p-3">
                <img src={toothlensLogo} alt="logo" className="h-8 w-24" />
                <div>
                    <button onClick={() => { navigate(`/${dentalOfficeName}/menu`) }}>  <img src={menu} alt="menu" className="w-8 h-8 ms-3" /> </button>

                </div>
            </div>
        )
    }

    const Profile = () => {
        return (
            <div className={`${!teethHealth && !isLoading && !isError && "flex-grow"} text-center mt-9 `}>
                <p className="text-lg leading-10">Hi {`${user.first_name ? user.first_name : ''} ${user.last_name ? user.last_name : ''}`}</p>
                {
                    isLoading ? null
                        : !isError ?
                            teethHealth ?
                                <p className="text-lg flex items-center justify-center ">
                                    <span>Your last oral health score looks</span>
                                    <button type="button" className="" onClick={() => { SetScoreCard(true) }}>
                                        <img src={infoIcon} alt="info" className="h-3 ms-2" />
                                    </button>
                                </p> :
                                <div className="">
                                    <p className="text-lg ">Let’s complete your profile</p>
                                    <div className="flex flex-col items-center mt-5">
                                        {
                                            isProfileLoading ? <p className="text-white py-20">Loading...</p> :
                                                <div className={`w-fit mb-1 ${!isProfileExist && "pe-8"}`}>
                                                    <img src={isProfileExist ? imageUrl : profileLogo} alt="profile_logo" className="min-h-40 max-h-48 rounded-full" />
                                                </div>
                                        }
                                        <button onClick={() => { navigate(`/${dentalOfficeName}/profile`) }} >Edit Profile</button>
                                    </div>
                                </div> : null
                }


            </div>
        )
    }

    const Loader = () => {
        return (
            <div className="flex-grow flex justify-center items-center">
                <p>Loading...</p>

            </div>
        )
    }

    const ErrorUI = () => {
        return (
            <div className="flex-grow flex justify-center items-center">
                <p className=" text-red-600">Something went wrong...</p>

            </div>
        )
    }



    const Footer = () => {
        return (
            <div className="w-full text-center mb-4 px-5">
                {

                    !isLoading ? !isError ?
                        teethHealth ? <button type="button" className=" bg-white text-black font-normal text-base w-full h-11 border border-black  mb-3" onClick={() => { navigate(`/${dentalOfficeName}/reports`) }}> View Reports </button>
                            : <p className="text-center font-medium text-base opacity-60 mb-10 mx-7 leading-7">Take your first scan to know your teeth health</p> : null : null

                }
                <button type="button" className="bg-black text-white w-full h-11 font-semibold text-base shadow-2xl " onClick={() => setReason(true)}>  Scan Now </button>
            </div>
        )
    }
    return (
        <div className="h-full flex flex-col">
            <Header />
            <Profile />
            {isError ? <ErrorUI /> : isLoading ? <Loader /> : teethHealth && <NewGraph />}
            <Footer />
            <ScoreCard isOpen={isScoreCardOpen} setIsOpen={SetScoreCard} />
            <LookingFor isOpen={reason} setIsOpen={setReason} />
        </div>

    )
}

export default Dashboard