
import { useState,useEffect } from "react"
import {formattedDate,formatDateToMMDDYY} from "../../Utils/getFormattedDate"
import { fontStyle,buttonStyle,fontWeight,company_style_details,companyBucket } from "../../Utils/tpscanStyle";

const TpProgress = () => {
    const [title,setTitle]=useState();
    const [color,setColor]=useState("text-white");
    const teethHealth= 100-JSON.parse(localStorage.getItem("tpscore")).overall_score
    const [progressColor,setProgressColor]=useState();


    useEffect(()=>{
        if ( teethHealth>=50) {
            setTitle("Good")
            setColor("text-green-500")  
            setProgressColor("#13D022")         
        } else if (teethHealth>25 ) {
            setTitle("Fair")
            setColor("text-amber-500")
            setProgressColor("#FE9B01")
        } else {
            setTitle("Poor")
            setColor("text-red-500")
            setProgressColor("#FF0000")
        }
    },[])
 
    return (
        <div className="m-7">
            <p className={`font-medium text-4xl text-amber-500 ${color}`} style={{...fontWeight,...fontStyle}}>{title}</p>
            <div className="border shadow-inner my-4 rounded-full">
                <div className="bg-primary h-3 rounded-full" style={{ width:`${teethHealth?teethHealth :0}%`,backgroundColor:progressColor}}></div>
            </div>
            <p className=" text-base" style={{...fontWeight,...fontStyle}}>Here's your Oral Health Score for {companyBucket=="bearn"?formatDateToMMDDYY(new Date()):formattedDate(Date.now())} </p>
        </div>
    )
}

export default TpProgress