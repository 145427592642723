import { useState } from "react"
import fairStatus from "../../assets/illustrations/State=Fair.png"
import goodStatus from "../../assets/illustrations/State=Good.png"
import poorstatus from "../../assets/illustrations/State=Poor.png"
import infoIcon from "../../assets/Icons/info.png"
import caretUp from "../../assets/Icons/CaretUp.png"
import caretDown from "../../assets/Icons/CaretDown.png"
import HealthPopup from "./healthPopup"
import { useSelector } from "react-redux"


const Info = () => {
    const teethHealth = useSelector((state => state.teethHealth))

    const status = [
        { type: "tooth_health", title: "Tooth", img: goodStatus, progress: caretUp },
        { type: "gum_health", title: "Gum", img: fairStatus, progress: caretUp },
        { type: "alignment_health", title: "Alignment", img: poorstatus, progress: caretDown }
    ]

    const [popup, setPopup] = useState(false)
    const [popupType, setPopupType] = useState()

    const openPopup = (data) => {
        setPopupType(data)
        setPopup(true)
    }

    const determineGumStatus = (score) => { // note : score is reserved wrt ml api
        let imgSrc = ''
        if (score >= 50) {
            imgSrc = goodStatus
        } else if (score > 25) {
            imgSrc = fairStatus
        } else {
            imgSrc = poorstatus
        }

        return imgSrc
    }

    const determineCavity = (score) => { // note : score is reserved wrt ml api
        let imgSrc = ''
        if (score === 100) {
            imgSrc = goodStatus
        } else if (score > 25) {
            imgSrc = fairStatus
        } else {
            imgSrc = poorstatus
        }

        return imgSrc

    }



    const Health = (props) => {
        const { data } = props

        let text = ''
        let imgSrc = ''
        let progressImg = ''

        if (teethHealth) {
            if (teethHealth.type === "comparison") {
                if (data.type === 'alignment_health') {
                    text = teethHealth.alignment_health ? "Found gaps" : "Found no gaps"
                    imgSrc = teethHealth.alignment_health ? fairStatus : goodStatus
                    progressImg = teethHealth.alignment_health ? caretDown : caretUp

                } else {
                    text = teethHealth[data.type].change === 0 ? "Same result" : `${teethHealth[data.type].status} by ${teethHealth[data.type].change}% `
                    imgSrc = data.type === "tooth_health" ? determineCavity(teethHealth[data.type]?.current_score) : determineGumStatus(teethHealth[data.type]?.current_score)
                    progressImg = teethHealth[data.type].change === 0 ? caretUp : teethHealth[data.type].status === "Declined" ? caretDown : caretUp

                }
            } else if (teethHealth.type === "latest") {
                if (data.type === 'alignment_health') {
                    text = teethHealth.alignment_health ? "Found gaps" : "Found no gaps"
                    imgSrc = teethHealth.alignment_health ? fairStatus : goodStatus
                    progressImg = ''

                } else {
                    text = `Scored ${teethHealth[data.type]?.current_score}% `
                    imgSrc = data.type === "tooth_health" ? determineCavity(teethHealth[data.type]?.current_score) : determineGumStatus(teethHealth[data.type]?.current_score)
                    progressImg = ''
                }
            }


            return (
                <div className="border-2 mt-1 mx-5 flex justify-start items-center">
                    <div className="my-2 ms-2 w-36 ">
                        <p className="my-1 text-base font-medium">{data.title} Health   </p>
                        <p className=" text-sm"> {text}  </p>
                    </div>
                    <div className="flex flex-col flex-grow">
                        <button type="button" className="mb-1" onClick={() => { openPopup(data.title) }}> <img src={infoIcon} alt="info" className="h-3" /></button>
                        {progressImg && <img src={progressImg} alt="info" className="mt-2 h-4 w-4" />}
                    </div>
                    {imgSrc && <img src={imgSrc} alt="status" className="h-11 me-4 mt-2" />}
                </div>
            )

        }
    }

    return (
        <>
            {
                status.map((eachStatus, i) => <Health key={i} data={eachStatus} />)
            }
            {popup && <HealthPopup popupType={popupType} data={"data"} setPopup={setPopup} />}
        </>
    )
}

export default Info