import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "../ScoreCard/index.css"
import { addOptions, addReason } from "../../Store/questionnaire";
import { createFullScanS3Folder } from "../../Store/fullScan";
import close from "../../assets/Icons/close.png"


const LookingFor = ({ isOpen, setIsOpen }) => {
    const { dentalOfficeName } = useParams();

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { selectedOptions, reason } = useSelector((state) => state.questionnaire)
    const { fullScanS3Folder } = useSelector((state) => state.fullScan)


    const options = [
        "I just need a routine check",
        "I think I have cavities",
        "I think I have gum problems",
        "I have some tooth discomfort",
        "I am looking for tooth replacement/bridges/implants",
        "I want a smile correction/braces/aligners",
        "I want whiter teeth",
        "Others"
    ]

    

    const handleOnchange = (e) => {
        if (e.target.checked) {
            const checkedItems = [...selectedOptions, e.target.value]
            dispatch(addOptions(checkedItems))
        } else {
            const unCheckItems = selectedOptions.filter(option => option !== e.target.value)
            dispatch(addOptions(unCheckItems))
        }
    }

    const takeScanNow = () => {
        !fullScanS3Folder && dispatch(createFullScanS3Folder())
        navigate(`/${dentalOfficeName}/full-scan`)
    }

    const Option = (data) => {
        return (
            <div className="flex pt-5 pb-4 border-b-2">
                <input type="checkbox" id={data.i} value={data.value} checked={selectedOptions.includes(data.value)} onChange={handleOnchange} className="h-4 w-4 me-3" />
                <label htmlFor={data.i} className=" text-sm" > {data.value}</label>
            </div>
        )
    }


    return (
        <div className={`popup ${isOpen ? 'open' : "closed"} z-20 absolute h-full w-full top-0 bg-black/[0.3] backdrop-blur-sm flex items-end`} >
            <div className="bg-white w-full flex flex-col" style={{ height: "95%" }}>
                <div className="flex justify-center">
                    <p className="dragger rounded my-1"></p>
                </div>
                <div className="flex-grow overflow-scroll">
                    <div className="flex">
                        <p className=" text-2xl py-3 px-5">What are you looking for?</p>
                        <button type="button" onClick={() => { setIsOpen(false) }}> <img src={close} className="w-6 h-6" alt="close" />  </button>
                    </div>
                    <div className="px-5">
                        {
                            options.map((option, i) => <Option key={i} value={option} i={i} />)
                        }
                    </div>
                    <div className="px-5 py-3" >
                        <textarea id="textarea" rows="2" cols="5" value={reason} onChange={e => { dispatch(addReason(e.target.value)) }} className="border w-full p-2" placeholder="Enter your reason"></textarea>
                    </div>
                    <div className='fixed w-full bottom-0 text-center p-5 bg-gray-300/[0.1] backdrop-blur-xs'>
                        <button type="submit" onClick={takeScanNow} disabled={selectedOptions.length === 0} className={`${selectedOptions.length === 0 ? "bg-zinc-400" : "bg-black"} text-white w-full h-11 font-semibold text-base shadow-2xl`} > Submit  </button>
                    </div>
                    <div className='h-28 '></div>
                </div>
            </div>
        </div>

    )
}

export default LookingFor