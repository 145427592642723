import React from 'react';
import { Skeleton, ListItem, Grid } from '@mui/material';

const ReportSkeleton = () => {
    return (
        <ListItem className='my-2'>
            <Grid container spacing={2} alignItems="center">
                <Grid item>
                    <Skeleton variant="circular" width={40} height={40} />
                </Grid>
                <Grid item xs>
                    <Skeleton variant="text" width="50%" />
                    <Skeleton variant="text" width="80%" />
                </Grid>
                <Grid item>
                    <Skeleton variant="text" width={40} height={25} />
                </Grid>
            </Grid>
        </ListItem>
    );
};


export default ReportSkeleton;
