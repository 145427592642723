
import { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { formattedDate } from "../../Utils/getFormattedDate";

const Progress = () => {
    const [title, setTitle] = useState()
    const [color, setColor] = useState("text-white")
    const teethHealth = useSelector((state) => state.teethHealth)


    useEffect(() => {
        if (teethHealth.overall_score) {
            const score = teethHealth.overall_score.current_score
            if (score >= 50) {
                setTitle("Good")
                setColor("text-green-500")
            } else if (score > 25) {
                setTitle("Fair")
                setColor("text-amber-500")
            } else {
                setTitle("Poor")
                setColor("text-red-500")
            }

        } else {
            setTitle("Null")
        }

    }, [teethHealth])

    return (
        <div className="m-7">
            <p className={`font-medium text-4xl ${color}`}> {title} </p>
            <div className="border shadow-inner my-4 rounded-full">
                <div className="bg-black h-3 rounded-full" style={{ width: `${teethHealth.overall_score?.current_score ?? 0}%` }}></div>
            </div>
            <p className=" text-base">Your last oral health score as per <br /> {formattedDate(teethHealth.date)} </p>
        </div>
    )
}

export default Progress