import React, { useState } from "react";
import { downloadPdfForBearn } from "../../Services/APIs/report";
import { postBookAppointment } from "../../Services/APIs/annotation";
import {
  fontStyle,
  buttonStyle,
  fontWeight,
  companyBucket,
} from "../../Utils/tpscanStyle";

const DownloadPdf = () => {
  const [DownloadStatus, setDownloadStatus] = useState("Download My Report");
  const download = async () => {
    setDownloadStatus("Downloading...");
    const file = localStorage.getItem("pdf");
    if (file) {
      const bucketName = JSON.parse(
        localStorage.getItem("response")
      ).bucket_name;
      await downloadPdfForBearn({ file, bucket_name: bucketName })
        .then((response) => {
          const url = response.data?.data?.info;
          const link = document.createElement("a");
          link.href = url;
          link.download = file; // Optional: Specify a download attribute
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setDownloadStatus("Downloaded");
          setTimeout(() => {
            setDownloadStatus("Download My Report");
          }, 2500);
        })
        .catch((error) => {
          setDownloadStatus("Failed, try again...");
          setTimeout(() => {
            setDownloadStatus("Download My Report");
          }, 2500);
        });
    } else {
      setDownloadStatus("Not Found");
    }
  };
  const bookAppointment = async () => {
    await postBookAppointment()
      .then((res) => {
        console.log(res);
        // window.open("https://ols-staging.mmgfusion.com/6658b278ba58106b97078212",'_blank')
        // window.location.href("https://ols-staging.mmgfusion.com/6658b278ba58106b97078212")
      })
      .catch((err) => console.log(err));
  };
  return (
    <div>
      <div className="  w-full px-5 py-3   bg-gray-300/[0.1] backdrop-blur-xs rounded ">
        {companyBucket == "bearn" && (
          <div>
            <button
              type="button"
              className=" text-white w-full h-11 font-semibold text-base rounded"
              style={{ ...buttonStyle, ...fontStyle, ...fontWeight }}
              // onClick={()=>{bookAppointment()}}
            >
              <a
                href="https://ols-staging.mmgfusion.com/6658b278ba58106b97078212"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Book An Appointment
              </a>
            </button>
          </div>
        )}
      </div>
      <div className="  w-full px-5 pb-3  bg-gray-300/[0.1] backdrop-blur-xs rounded ">
        {DownloadStatus === "Downloaded" ? (
          <p
            style={{ ...fontStyle, ...fontWeight }}
            className="py-3 bg-green-100 rounded text-center font-semibold text-green-900"
          >
            Downloaded successfully
          </p>
        ) : (
          <button
            type="button"
            className={
              companyBucket == "bearn"
                ? "bg-white text-black w-full h-11 font-semibold text-base rounded "
                : "bg-black text-white w-full h-11 font-semibold text-base rounded shadow-2xl"
            }
            onClick={() => {
              download();
            }}
            style={{ ...fontStyle, border: "1px solid #575EE4" }}
          >
            {DownloadStatus}
          </button>
        )}
      </div>
    </div>
  );
};

export default DownloadPdf;
