import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import stars from "../../assets/illustrations/bg-stars.png";
import close from "../../assets/Icons/close-white.png";
import lady from "../../assets/illustrations/lady.png";
import arrow from "../../assets/Icons/arrow.png";
import { clearReports } from "../../Store/reports";
import { clearImages } from "../../Store/fullScan";
import { clearQuickScan } from "../../Store/quickScan";
import { logoutService } from "../../Services/APIs/authentication";
import { clearHealth } from "../../Store/teethHealth";
import { clearProfile, getProfileLogo, noProfile } from "../../Store/profile";

const Menu = () => {
  const { dentalOfficeName } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = localStorage.getItem("user")
    ? JSON.parse(localStorage.getItem("user")).user
    : {};
  const { imageUrl, isProfileExist, isProfileLoading } = useSelector(
    (state) => state.profileLogo
  );

  useEffect(() => {
    if (user?.profile) {
      !imageUrl && dispatch(getProfileLogo({ profile: user.profile }));
    } else {
      dispatch(noProfile());
    }
  }, []);

  const logout = () => {
    dispatch(clearReports());
    dispatch(clearImages());
    dispatch(clearQuickScan());
    dispatch(clearHealth());
    dispatch(clearProfile());
    logoutService().finally(() => {
      navigate(`/${dentalOfficeName}`);
    });
  };

  const CustomButton = ({ text, route }) => {
    return (
      <button
        type="button"
        onClick={() => {
          navigate(`${route}`);
        }}
        className="w-full  text-start py-4 border-b-2 border-gray-200 flex items-center justify-between"
      >
        <span> {text} </span>
        <img src={arrow} alt="Arrow" className=" h-6" />
      </button>
    );
  };

  return (
    <div className="h-full">
      <div className=" bg-black" style={{ height: "55%" }}>
        <img src={stars} alt="stars" className="h-full w-full object-cover" />
      </div>
      <div className="h-full w-full absolute top-0  flex flex-col">
        <div className="text-end p-3">
          <button
            type="button"
            className="text-end"
            onClick={() => {
              window.history.back();
            }}
          >
            <img src={close} alt="close" className="h-7" />
          </button>
        </div>
        <div className=" flex-grow flex flex-col text-center">
          <div className="flex justify-center">
            {isProfileLoading ? (
              <div className="loader-container">
                <div className="loader dark-loader my-20"></div>
              </div>
            ) : (
              <div className="rounded-full w-44 h-44">
                <img
                  src={isProfileExist ? imageUrl : lady}
                  alt="lady"
                  className="w-full h-full object-contain rounded-full"
                />
              </div>
            )}
          </div>
          <p className=" font-medium text-2xl text-white ">
            {`${user.first_name ? user.first_name : ""}  ${
              user.last_name ? user.last_name : ""
            }`}
          </p>
          <div className=" flex-grow flex justify-center items-center">
            <div className="w-5/6 h-5/6  rounded-xl shadow-lg bg-white pe-2 ps-3 overflow-scroll">
              <CustomButton
                text={"Profile"}
                route={`/${dentalOfficeName}/profile`}
              />
              <CustomButton
                text={"View reports"}
                route={`/${dentalOfficeName}/reports`}
              />
              <CustomButton
                text={"FAQ"}
                route={`/${dentalOfficeName}/dashboard`}
              />
              <CustomButton
                text={"Feedback"}
                route={`/${dentalOfficeName}/feedback`}
              />
              <a href="mailto:support@toothlens.com">
              <button
                type="button"
                className="w-full  text-start py-4 flex items-center justify-between border-b-2 border-gray-200"
              >
                  <span>Support</span>
                  <img src={arrow} alt="Arrow" className=" h-6" />
              </button>
              </a>

              <CustomButton
                text={"Privacy policy"}
                route={`/${dentalOfficeName}/privacypolicy`}
              />

              <button
                type="button"
                onClick={logout}
                className="w-full  text-start py-4 flex items-center justify-between border-b-2 border-gray-200"
              >
                <span> Logout </span>
                <img src={arrow} alt="Arrow" className=" h-6" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Menu;
