import React from "react";

const Policy = () => {
  return (
    <div className="px-4">
      <h1 className="font-bold text-center my-3 text-xl">PRIVACY POLICY</h1>
      <div className="font-bold my-3">1. Background</div>
      <div>
        1.1 This website, www.toothlens.com, and its associated domains
        including but not limited to, smartcheck.toothlens.com (the “Website”)
        is owned and operated by Toothlens Inc., 651N, Broad Street, Middletown
        Delaware 19709, United States of America (the “Company” “Toothlens” or
        “We/we” or “Us/us”).
      </div>
      <div>
        1.2 This privacy policy describes the manner in which we collect,
        process, store, share, retain and purge your personal information and/or
        data that we receive from you when you visit our Website or which we
        otherwise collect from you (the “Privacy Policy”).
      </div>
      <div>
        1.3 By using the Website, you hereby consent to the manner in which the
        Company deals with your Personal Data.
      </div>
      <div>
        1.4 Protecting your Personal Data is our priority and usage of your
        Personal Data shall be in accordance with applicable laws governing data
        protection and privacy in the United States of America (the “USA”).
      </div>
      <div className="font-bold my-3">2. Meanings</div>
      <div>
        2.1 The term “Personal Data” shall mean any information that is linked
        or reasonably linkable to an identified or identifiable individual, and
        does not include de-identified data or publicly available information.
        It shall however exclude any information that is specifically barred
        and/or restricted from being provided in any legal jurisdiction.
      </div>
      <div>
        2.2 The term “You/you” or “Your/your” or “Yourself/yourself”’ shall
        refer to any user using the services and/or visitor visiting the
        Website.
      </div>
      <div className="font-bold my-3">3. Applicability</div>
      <div>
        3.1 This Privacy Policy describes the manner in which the Company
        collects, uses or processes, stores and discloses your Personal Data and
        the choices you have made with respect to the manner in which the
        Company ought to be dealing with your Personal Data.
      </div>
      <div>
        3.2 This Privacy Policy is limited in its applicability only to your use
        of the Website.
      </div>
      <div className="font-bold my-3">4. General Consent</div>
      <div>
        4.1 You agree to provide your express consent for the Company to
        collect, process, store, share, retain and purge the Personal Data
        submitted by you.
      </div>
      <div>
        4.2 The Company respects your privacy. You shall be entitled to withdraw
        your consent at any time by writing to privacy@toothlens.com.
      </div>
      <div>
        4.3 The Company updates this Privacy Policy from time to time. You shall
        be notified of such changes via email communication or by a notice on
        our Website seeking your acknowledgement and consent to such changes.
      </div>
      <div>
        4.4 Your continued use of the Website and/or Services available through
        the Website despite such notice will constitute your acknowledgement of
        the modified Privacy Policy and commitment to abide by the same.
      </div>
      <div className="font-bold my-3">5. Consent by Minor</div>
      <div>
        5.1 If you are a minor, you shall be entitled to submit your Personal
        Data while using the Website only with the expressed consent of your
        parent or guardian.
      </div>
      <div>
        5.2 In the event of discovery of incorrect or inadequate information,
        the Company shall, without any notice, be at the sole discretion to
        remove the information from its system and abstain your access to the
        Website.
      </div>
      <div className="font-bold my-3">6. Collection and Use of Information</div>
      <div>
        6.1 In furtherance to providing you services offered by us, we will
        collect your Personal Data.
      </div>
      <div>
        6.2 We shall provide our services to you only if your Personal Data is
        true, correct, accurate and up-to- date. We shall be entitled to
        withdraw such services immediately upon discovery of its incorrectness
        and/or inaccuracy without any notice.
      </div>
      <div>
        6.3 We shall not collect your Personal Data unless you provided it to us
        voluntarily or through your consent, which shall be kept as strictly
        confidential.
      </div>
      <div>
        6.4 We shall also be entitled to collect and store certain user-related
        information, in accordance with applicable laws, whenever you visit or
        utilize our services which is not associated with your Personal Data and
        shall include but is not limited to data that identifies your device,
        application and your activity.
      </div>
      <div>
        6.5 The Personal Data along with all supporting documents/images
        submitted by you shall be used for the limited purpose of:
      </div>
      <div>
        a) administering and providing the Services and contacting you in
        furtherance to it;
      </div>
      <div>
        b) enable your access in order to use the services including delivering
        mobile and/or email notifications like one-time passwords, providing
        confirmations, alerts, updates, et cetera;
      </div>
      <div>c) personalizing the services for you;</div>
      <div>d) providing better customer experience;</div>
      <div>e) publish your user feedback on our services;</div>
      <div>
        f) responding to your queries, requests and legitimate claims and
        resolving any issues;
      </div>
      <div>
        g) keeping you informed or updating you on various products and
        services;
      </div>
      <div>h) sending you such information that may be of interest to you;</div>
      <div>i) archival or backup purpose(s);</div>
      <div>
        j) cooperating with government and regulatory authorities, judicial,
        quasi-judicial, or regulators in accordance with our legal obligations
        under applicable laws to the extent required;
      </div>
      <div>
        k) notifying you (upon our reasonable belief or otherwise) of any
        violation of any third party rights, law, agreements and/or policies;
      </div>
      <div>
        l) sharing your data with trusted partners to help perform statistical
        analysis;
      </div>
      <div>
        m) conducting research and performing analysis in order to measure,
        maintain, protect, develop and improve our Services;
      </div>
      <div>
        n) monitoring aggregated metrics such as the total number of visitors
        and traffic; sending you marketing communications;
      </div>
      <div>
        o) to send you e-mails or contact you for various customer satisfaction
        surveys, market research, promotional activities or in connection with
        certain transactions.
      </div>
      <div>
        6.6 The Company shall take all reasonable measures as prescribed under
        law to prevent unauthorized access and misuse of your Personal Data.
        However, the Company shall not be responsible for any such unauthorized
        access and misuse of your Personal Data by unauthorized persons despite
        having taken all such measures.
      </div>
      <div className="font-bold my-3">7. Data Sharing & Data Access</div>
      <div>
        7.1 We shall not use your Personal Data for any purpose other than in
        furtherance to providing the services.
      </div>
      <div>7.2 We may share your Personal Data in the following instances:</div>
      <div>
        a) with our business partner in furtherance to providing the services,
        upon taking your express written “prior consent” and on a “need to know”
        basis; and
      </div>
      <div>
        b) with any judicial, quasi-judicial, regulatory, statutory and/or any
        government agency pursuant to any legal requirement.
      </div>
      <div>
        7.3 We shall not rent, sell, or otherwise provide your Personal Data
        received from you to any third party without your consent, except as
        described herein or as required by law.
      </div>
      <div className="font-bold my-3">8. Data Transfer</div>
      <div>
        8.1 Unless expressly permitted under law and by express consent by you,
        we shall not transfer your Personal Data with any third party, within
        and/or outside the territorial jurisdiction of the USA.
      </div>
      <div>
        8.2 In the event of a merger or acquisition or change of control of the
        Company with/by another entity, the Company reserves the right to share
        and/or transfer all or a portion of your Personal Data with such other
        entity.
      </div>
      <div className="font-bold my-3">9. Public Data</div>
      <div>
        9.1 Any information that is freely available or accessible in the public
        domain or furnished under any law for the time being in force shall not
        be regarded as Personal Data.
      </div>
      <div>
        9.2 We shall not be responsible in any manner of whatsoever nature for
        any unauthorized use, violation or misuse of such publicly available
        information.
      </div>
      <div className="font-bold my-3">10. Data Retention and Storage</div>
      <div>
        10.1 We shall retain your Personal Data for such period as may be
        prescribed by law from time to time.
      </div>
      <div>
        10.2 In the event that we are unable to process the Personal Data
        submitted by you for whatsoever reason(s) or when the purpose of
        collection and/or processing of your Personal Data is fulfilled, your
        Personal Data shall be deleted unless it is required by us to retain
        such Personal Data under law and/or as may be required for any internal
        or external audit purposes.
      </div>
      <div>
        10.3 We are committed to comply with applicable laws to ensure that all
        measures, so prescribed by law, are taken for storing the Personal Data
        in order to ensure safety and security of your Personal Data.
      </div>
      <div>
        10.4 We may store your data on servers provided by any third-party
        hosting vendor whom we have a valid contract with.
      </div>
      <div className="font-bold my-3">2. Data Purging</div>
      <div>
        2.1 The Company has necessary policies, mechanisms and tools in place to
        ensure that all your Personal Data collected and stored is deleted as
        soon as the purpose, as described in this Policy is completed, unless it
        is mandated by law to be maintained by the Company.
      </div>
      <div>
        2.2 Any data destroyed shall be disposed of in a manner that protects
        the confidentiality of your Personal Data, in an appropriate manner as
        per the industry standard practices and norms.
      </div>
      <div className="font-bold my-3">3. Your Rights</div>
      <div>
        3.1 Under the laws relating to privacy and data protection in the USA,
        you have the following rights with respect to your Personal Data:
      </div>

      <div>a) The right to access your Personal Data.</div>
      <div>b) The right to correct your Personal Data.</div>
      <div>c) The right to delete your Personal Data.</div>
      <div>d) The right to obtain a portable copy of your Personal Data.</div>
      <div>
        e) The right to obtain a list of uses and disclosures of your Personal
        Data.
      </div>
      <div>f) The right to opt out of certain processing activities.</div>
      <div className="font-bold my-3">4. Data Security</div>
      <div>
        4.1 We shall take all reasonable measures to prevent unauthorized
        access, use and disclosure of your Personal Data.
      </div>
      <div>
        4.2 In furtherance to keeping your Personal Data safe and secure, the
        Company may collect the following information:
      </div>
      <div>
        a) Anonymous data from every visitor of the Website to monitor traffic
        and fix bugs which includes (without limitation) information like web
        requests, the data sent in response to such requests, the Internet
        Protocol (“IP”) address, the browser type, the browser language, unique
        5 identifiers of the device through which the application is accessed,
        such as VPN, information of Wi-Fi connectivity and a timestamp for the
        request; and
      </div>
      <div>
        b) Log file information is automatically reported by your browser each
        time the application is viewed. The server supporting the application
        logs may include information such as web request, IP address, Analytical
        Code, Geo Stamp, browser type, browser language, referring/exit pages
        and Universal Resource Locators (“URL”), platform type, number of
        clicks, domain names, landing pages, pages viewed and the order of those
        pages, the amount of time spent on a particular page, the date and time
        of the request (“Log File Information”). Such Log File Information
        collected is not associated with any Personal Information and is only
        tagged to the unique identifier for a particular device.
      </div>
      <div className="font-bold my-3">5. Use of Cookies</div>
      <div>
        5.1 We may use cookies to help you personalize your online experience,
        which is a text file placed on your hard disk by a webpage server.
      </div>
      <div>
        5.2 Cookies are uniquely assigned to you and can only be read by a web
        server in the domain that issued the cookies to you. Cookies cannot be
        used to run programs or deliver viruses to your computer.
      </div>
      <div className="font-bold my-3">11. Do Not Track (“DNT”)</div>
      <div>
        11.1 We respect your privacy and we respond to your Do Not Track (“DNT”)
        signals.
      </div>
      <div>
        11.2 You are requested to enable the DNT feature on your internet/web
        browser in order to ensure that you are in control of tracking. You can
        enable or disable DNT by visiting the preferences or settings page of
        your web browser.
      </div>
      <div className="font-bold my-3">6. Liability</div>
      <div>
        6.1 The Company shall in no manner be liable for any violation under
        this Privacy Policy, if the Personal Data,
      </div>
      <div>a) is already available in the public domain; or</div>
      <div>b) is obtained by you using illegal and/or unlawful means; or</div>
      <div>
        c) is provided by any person who is incompetent under the applicable
        laws governing contracts.
      </div>
      <div className="font-bold my-3">7. Grievance Redressal</div>
      <div>
        7.1 You have the right to ‘opt-out’ of providing your Personal Data and
        may withdraw your ‘Consent’ by expressly writing to
        privacy@toothlens.com.
      </div>
      <div>
        7.2 In the event of any grievance, you may write to
        privacy@toothlens.com. If your grievance is not redressed within [●]
        business days, you are requested to escalate your grievance to
        privacy.escalations@toothlens.com.
      </div>
      <div className="font-bold my-3">8. Dispute Resolution</div>
      <div>
        8.1 Any dispute remaining unresolved for a period of over 30 (thirty)
        business days, post exhausting grievance redressal mechanism, may be
        resolved by referring such disputes to the Department of Health and
        Human Services’ Office for Civil Rights (the “HHS Department”).
      </div>
      <div className="font-bold my-3">9. Governing Law</div>
      <div>
        9.1 This Privacy Policy shall be governed by the laws applicable to the
        USA, particularly the State of Delaware.
      </div>
      <div>
        9.2 The courts of the State of Delaware shall be the court of competent
        jurisdiction to try disputes between the parties herein.
      </div>
      <div className="font-bold my-3">10. Changes to This Privacy Policy</div>
      <div>
        10.1 The Company reserves the right to change this Privacy Policy from
        time to time as may be required under any law.
      </div>
      <div>
        10.2 We will notify you about any and all significant changes in the
        Privacy Policy by sending a notice to the primary email address
        specified in your account or placing a notice on our Website seeking
        your acknowledgement and consent to such changes.
      </div>
      <div>
        10.3 Your continued use of the Website and/or Services available through
        the Website despite such notice will constitute your acknowledgement of
        the modified Privacy Policy and commitment to abide by the same.
      </div>
      <div className="font-bold my-3">11. Email Communications</div>
      <div>
        11.1 From time to time, we may contact you via email to provide
        announcements, promotional offers, alerts, confirmations, surveys,
        and/or other general communication. To improve our products and/or
        services, you may receive a notification from us either by email and/or
        message.
      </div>
      <div>
        11.2 If you would like to stop receiving marketing or promotional
        communications via email from us, you may opt out of such communications
        by clicking on the “unsubscribe” button.
      </div>
      <div className="font-bold my-3">12. Contact Information</div>
      <div>
        12.1 The Company welcomes your questions or comments regarding this
        Privacy Policy at info@toothlens.com.
      </div>
    </div>
  );
};

export default Policy;
