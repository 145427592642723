import React, { useState, useEffect } from "react";
import { gapi, loadAuth2 } from "gapi-script";
// import { Button } from '@material-ui/core';
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { LoginService } from "../../../Services/APIs/authentication";

const CLIENT_ID =  "271277176231-ub4fk7uj4qd39ce153vi376urm67395u.apps.googleusercontent.com";

const GoogleLogin = (props) => {
  const [user, setUser] = useState(null);
  const { dentalOfficeName } = useParams();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [customError, setCustomError] = useState("");
  useEffect(() => {
    const setAuth2 = async () => {
      const auth2 = await loadAuth2(gapi, CLIENT_ID, "");
      if (auth2.isSignedIn.get()) {
        // updateUser(auth2.currentUser.get());
      } else {
        attachSignin(document.getElementById("customBtn"), auth2);
      }
    };
    setAuth2();
  }, []);

  useEffect(() => {
    if (!user) {
      const setAuth2 = async () => {
        const auth2 = await loadAuth2(gapi, CLIENT_ID, "");
        attachSignin(document.getElementById("customBtn"), auth2);
      };
      setAuth2();
    }
  }, [user]);

  const updateUser = async (currentUser) => {
    setLoading(true);
    const appDetails = JSON.parse(localStorage.getItem("appDetails"));
    const user = {
      email: currentUser.getBasicProfile().getEmail(),
      first_name: currentUser.getBasicProfile().getGivenName(),
      last_name: currentUser.getBasicProfile().getFamilyName(),
      is_sso: true,
      advertising_url: appDetails?.app_url ? appDetails.app_url : "",
      is_advertising_user: appDetails?.app_url ? true : false,
    };
    setUser(user);
    await LoginService(user)
      .then((response) => {
        setLoading(false);
        localStorage.setItem("authToken", response.headers.authorization);
        localStorage.setItem("user", JSON.stringify(response.data.data));
        navigate(`/${dentalOfficeName}/onboarding`);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response?.status === 404) {
          setCustomError(
            error.response?.data?.message || "An unexpected error occurred"
          );
        }
      });
  };

  const attachSignin = (element, auth2) => {
    auth2.attachClickHandler(
      element,
      {},
      (googleUser) => {
        updateUser(googleUser);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  return (
    <div className="w-full">
       <div className=" bg-white text-black font-normal text-base  w-full h-11 flex flex-row justify-center items-center border border-black mt-4">
      <button id="customBtn" type="button">
        {isLoading ? (
          <div className="loader-container">
            <div className="loader"></div>
          </div>
        ) : (
          <div>
            <div>
            <svg
              className="inline"
              width="18"
              height="18"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g fill="#000" fillRule="evenodd">
                <path
                  d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z"
                  fill="#EA4335"
                ></path>
                <path
                  d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z"
                  fill="#4285F4"
                ></path>
                <path
                  d="M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z"
                  fill="#FBBC05"
                ></path>
                <path
                  d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z"
                  fill="#34A853"
                ></path>
                <path fill="none" d="M0 0h18v18H0z"></path>
              </g>
            </svg>
            <span className="ml-2">Login with google</span>
            </div>

          </div>

        )}
      </button>

    </div>
    <div>
   {customError && <p className="text-red-500 font-medium text-center text-xs m-2"> {customError}</p>}

    </div>

    </div>
   
  );
};

export default GoogleLogin;
