import React, { useState } from "react";
import feedback from "../../assets/feedbackImages/feedbackImage.png";
import close from "../../assets/feedbackImages/close.png";
import { useNavigate, useParams } from "react-router-dom";
import { updateProfile } from "../../Services/APIs/profile";
const Feedback = () => {
  const { dentalOfficeName } = useParams();
  const navigate = useNavigate();
  const [rating, setRating] = useState(5);
  const [isLoading, setLoading] = useState(false);
  const [customError, setCustomError] = useState("");
  const [DownloadStatus, setDownloadStatus] = useState("Download");

  const handleClick = (index) => {
    setRating(index);
  };
  const handleSubmit = async () => {
    setLoading(true);
    await updateProfile({ feedback: rating })
      .then((res) => {
        setLoading(false);
        setTimeout(() => {
          setDownloadStatus("Download");
        }, 2500);
        setDownloadStatus("Downloaded");
      })
      .catch((error) => {
        setLoading(false);
        setCustomError(
          error.response?.data?.messagde || "An unexpected error occurred"
        );
      });
  };
  return (
    <div className="px-2 pt-2 h-screen">
      <div className="h-1/2  flex justify-center items-center relative">
        <div className="mt-3">
          <img src={feedback} alt="feedback" />
        </div>
        <div className="absolute top-0 right-0 ">
          <img
            src={close}
            alt="close"
            onClick={() => {
              navigate(`/${dentalOfficeName}/menu`);
            }}
          />
        </div>
      </div>
      <div className="h-1/2">
        <div className=" text-2xl font-medium mb-5">
          We Value Your Feedback!
        </div>
        <div className=" text-base font-medium my-5">
          How would you rate your experience with Toothlens?
        </div>
        <div className="my-5">
          <div className="flex justify-center">
            {[...Array(5)].map((_, index) => (
              <svg
                width="89"
                height="88"
                viewBox="0 0 89 88"
                xmlns="http://www.w3.org/2000/svg"
                key={index}
                fill="currentColor"
                className={` h-16 cursor-pointer ${
                  index < rating
                    ? "text-yellow-500 text-gradient"
                    : "text-gray-100"
                }`}
                onClick={() => handleClick(index + 1)}
              >
                <g filter="url(#filter0_d_657_10608)">
                  <path
                    d="M45.7734 53.573C44.9891 53.104 44.0106 53.104 43.2264 53.573L33.4431 59.4229C32.2924 60.111 30.8797 59.0791 31.1852 57.7736L33.7527 46.803C33.9635 45.9023 33.6558 44.9591 32.9544 44.356L24.3809 36.9843C23.3607 36.107 23.901 34.4343 25.2417 34.3198L36.5893 33.3504C37.5023 33.2724 38.2981 32.6975 38.659 31.8552L43.1041 21.4803C43.6299 20.253 45.3699 20.253 45.8957 21.4804L50.3408 31.8552C50.7017 32.6975 51.4975 33.2724 52.4105 33.3504L63.752 34.3193C65.0934 34.4339 65.6334 36.1079 64.6118 36.9847L56.0248 44.3545C55.3213 44.9583 55.013 45.9039 55.2255 46.8063L57.8064 57.767C58.114 59.073 56.7006 60.1069 55.549 59.4183L45.7734 53.573Z"
                    stroke="black"
                    stroke-opacity="0.3"
                    stroke-width="0.962918"
                    shape-rendering="crispEdges"
                  />
                </g>
                <defs>
                  <filter
                    id="filter0_d_657_10608"
                    x="0.258133"
                    y="0.819766"
                    width="88.4779"
                    height="86.2669"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="3.85167" />
                    <feGaussianBlur stdDeviation="11.555" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_657_10608"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_657_10608"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
            ))}
          </div>
        </div>
        <div className="my-3">
          <button
            type="button"
            className=" bg-black text-white  font-semibold text-base w-full h-11 shadow-xl   mb-4"
            onClick={() => handleSubmit()}
          >
            {isLoading ? (
              <div className="loader-container">
                <div className="loader"></div>
              </div>
            ) : DownloadStatus === "Downloaded" ? (
              <p className="py-3 bg-green-100 rounded text-center border border-black font-semibold text-green-900">
                Feedback Updated successfully
              </p>
            ) : (
              <span> Submit</span>
            )}
          </button>
          {customError && (
            <p className="text-red-500 font-medium text-xs text-center mb-2">
              {customError}
            </p>
          )}
          <button
            type="button"
            className="bg-white text-black w-full h-11 font-normal border border-black text-base "
            onClick={() => {
              navigate(`/${dentalOfficeName}/menu`);
            }}
          >
            Ask me Later
          </button>
        </div>
      </div>
    </div>
  );
};

export default Feedback;
