import React, { useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import back from "../../assets/Icons/Back.png"
import profileLogo from "../../assets/illustrations/profile_logo.png"
import pen from "../../assets/Icons/Edit.png"
import cid from "../../assets/Icons/cid.png"
import phone from "../../assets/Icons/phone.png"
import email from "../../assets/Icons/email.png"
import location from "../../assets/Icons/location.png"
import changePassword from "../../assets/Icons/Change_password.png"
import Edit from "./edit"
import { getProfileLogo, noProfile } from "../../Store/profile"

const Profile = () => {
    const { dentalOfficeName } = useParams();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [isProfile, setProfile] = useState(true)
    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).user : {}
    const { imageUrl, isProfileLoading, isProfileExist } = useSelector((state) => state.profileLogo)

    useEffect(() => {
        if (user.profile) {
            !imageUrl && dispatch(getProfileLogo({ profile: user.profile }))
        } else {
            dispatch(noProfile())
        }

    }, [isProfile])


    const Information = () => {
        return (
            <>
                <div className="flex flex-col items-center mt-5 ">
                    {
                        isProfileLoading ?
                            <div className="loader-container">
                                <div className="loader dark-loader my-20"></div>
                            </div> :
                            <div className={`w-fit mb-1 ${!isProfileExist && "pe-8"}`}>
                                <img src={isProfileExist ? imageUrl : profileLogo} alt="profile_logo" className="min-h-40 max-h-48 rounded-full" />
                            </div>
                    }

                    <p className="text-2xl font-light">Hello, Welcome Back!</p>
                    <p className="text-2xl"> {`${user.first_name ? user.first_name : ''} ${user.last_name ? user.last_name : ''}`} <button onClick={() => { setProfile(false) }}><img src={pen} alt="edit" className="w-4 h-4" /></button></p>
                </div>
                <div className="bg-white shadow-lg m-5 px-3">
                    <p className="flex items-center border-b py-4"><img src={email} alt="" className="w-4 h-4 mx-2" /> <span> {user.email ? user.email : ''} </span></p>
                    <p className="flex items-center border-b py-4"><img src={phone} alt="" className="w-4 h-4 mx-2" /><span> {user.phone_number ? user.phone_number : ''} </span> </p>
                    <p className="flex items-center border-b py-4"><img src={location} alt="" className="w-4 h-4 mx-2" /><span> {user.address ? user.address : ''}, {user.city ? user.city : ''}, {user.state ? user.state : ''}, {user.zip_postal_code ? user.zip_postal_code : ''}</span></p>
                    <p className="flex items-center border-b py-4"><img src={changePassword} alt="" className="w-4 h-4 mx-2" /><button type="button" onClick={() => { navigate(`/${dentalOfficeName}/change-password`) }} className=" font-bold" >Change Password</button></p>
                </div>
            </>
        )
    }

    return (
        <div className=" bg-slate-50 h-full">
            <div className="flex items-center p-2 pt-3 ">
                <button onClick={() => { isProfile ? navigate(`/${dentalOfficeName}/dashboard`) : setProfile(true) }} className="me-3">
                    <img src={back} alt="back" className="w-8 h-8" />
                </button>
                <p className=" font-medium text-base">Profile</p>
            </div>
            {isProfile ? <Information /> : <Edit setProfile={setProfile} />}

        </div>
    )
}


export default Profile