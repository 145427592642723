import { useSelector } from "react-redux"
import Shoot from "./shoot"
import { useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
const QuickScan = () => {
    const { dentalOfficeName } = useParams();

    const navigate = useNavigate()
    const { quickScanS3Folder } = useSelector((state) => state.quickScan)

    useEffect(() => {
        if (!quickScanS3Folder) {
            navigate(`/${dentalOfficeName}/dashboard`)

        }
    }, [])

    return (
        <Shoot activeImageSide={0} setToothPicker={() => { }} quickScan={true} />
    )
}

export default QuickScan