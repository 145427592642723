import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import ToothSelector from "./toothSelector"
import Shoot from "./shoot"


const FullScan = () => {
    const { dentalOfficeName } = useParams();
    const [isToothPicker, setToothPicker] = useState(true)
    const [activeImageSide, setImageSide] = useState(0)
    const { fullScanS3Folder } = useSelector((state) => state.fullScan)
    const { tpScanS3Folder } = useSelector((state) => state.tpScan)
    const navigate = useNavigate()
    
    useEffect(() => {
        if (!fullScanS3Folder && !localStorage.getItem("usertpi")) {
            navigate(`/${dentalOfficeName}/dashboard`)
        }
    }, [isToothPicker, activeImageSide])

    return (
        <div className="h-full">
            {
                isToothPicker ?
                    <ToothSelector setImageSide={setImageSide} setToothPicker={setToothPicker} /> :
                    <Shoot activeImageSide={activeImageSide} setToothPicker={setToothPicker} quickScan={false} />
            }
        </div>
    )
}

export default FullScan