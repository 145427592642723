import { useState } from "react"
import { useNavigate, useParams } from "react-router-dom";
import { Form, ErrorMessage, Field, Formik } from "formik"
import BackButton from "../../BackButton"
import eye from "../../../assets/Icons/eye.png"
import * as Yup from 'yup';
import { changePassword } from "../../../Services/APIs/authentication";

const ChangePassword = () => {
    const { dentalOfficeName } = useParams();

    const navigate = useNavigate()
    const [currentPass, setCurrentPassVisible] = useState(false)
    const [newPass, setNewPassVisible] = useState(false)
    const [confirmPass, setConfirmPassVisible] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [passwordError, setPasswordError] = useState(false)
    const [status, setStatus] = useState({ isComplete: false, isSuccess: true })

    const initialValues = {
        current_password: '',
        new_password: '',
        confirm_password: ''
    }

    const validateSchema = () => Yup.object().shape({
        current_password: Yup.string().min(6, 'Current password must be at least 6 characters').required("Current password is required"),
        new_password: Yup.string()
            .min(6, 'New password must be at least 6 characters')
            .notOneOf([Yup.ref("current_password"), null], 'New password must be different from current password')
            .required("New Password is required"),
        confirm_password: Yup.string().oneOf([Yup.ref('new_password'), null], 'Passwords must match').required("Confirm password is required.")
    })

    const handleSubmit = async values => {
        const data = { password: values.new_password, oldPassword: values.current_password }
        setLoading(true)
        setPasswordError(false)
        setStatus({ isComplete: false, isSuccess: true })
        await changePassword(data).then((response) => {
            setLoading(false)
            setStatus({ isComplete: true, isSuccess: true })
            setTimeout(() => {
                localStorage.clear()
                navigate(`/${dentalOfficeName}/login`)
            }, 1000)

        }).catch((error) => {
            setLoading(false)
            if (error.response?.status === 401) {
                setPasswordError(true)
            } else {
                setStatus({ isComplete: true, isSuccess: false })
            }
        })
    }


    return (
        <div className="h-full">
            <Formik
                initialValues={initialValues}
                validationSchema={validateSchema}
                onSubmit={handleSubmit}
            >
                {({errors}) => (
                    <Form className="h-full flex flex-col ">
                        <div className="flex items-center">
                            <BackButton onClick={() => { window.history.back() }} />
                            <p className="text-base">Change password</p>
                        </div>
                        <p className="opacity-60 px-8 py-5 text-base">Your new password must be unique and different from previously used password.</p>
                        <div className="flex-grow p-6  bordher-4">
                            <div className='relative'>
                                <label htmlFor="current_password" className='text-xs mx-2'>Current Password</label>
                                <Field type={`${currentPass ? "text" : "password"}`} id="current_password" name="current_password" className={`h-10 px-2 text-sm mt-1 shadow-lg border border-black w-full`} placeholder="Enter current password" />
                                <button type='button' className=' p-2  absolute right-1 top-8' onClick={() => { setCurrentPassVisible(prevState => !prevState) }}>
                                    <img src={eye} alt="eye" className='  h-3' />
                                </button>
                                <ErrorMessage name="current_password" component="div" className="text-red-500 font-medium text-xs m-2" />
                                {passwordError && !errors.current_password && <div className="text-red-500 font-medium text-xs m-2">Current password is incorrect </div>}
                            </div>
                            <div className='relative mt-5'>
                                <label htmlFor="new_password" className='text-xs mx-2'>New Password</label>
                                <Field type={`${newPass ? "text" : "password"}`} id="new_password" name="new_password" className={`h-10 px-2 text-sm mt-1 shadow-lg border border-black w-full`} placeholder="Enter new password" />
                                <button type='button' className=' p-2  absolute right-1 top-8' onClick={() => { setNewPassVisible(prevState => !prevState) }}>
                                    <img src={eye} alt="eye" className='  h-3' />
                                </button>
                                <ErrorMessage name="new_password" component="div" className="text-red-500 font-medium text-xs m-2" />
                            </div>
                            <div className='relative mt-5'>
                                <label htmlFor="confirm_password" className='text-xs mx-2'>Confirm New Password</label>
                                <Field type={`${confirmPass ? "text" : "password"}`} id="confirm_password" name="confirm_password" className={`h-10 px-2 text-sm mt-1 shadow-lg border border-black w-full`} placeholder="Re-enter new password" />
                                <button type='button' className=' p-2  absolute right-1 top-8' onClick={() => { setConfirmPassVisible(prevState => !prevState) }}>
                                    <img src={eye} alt="eye" className='  h-3' />
                                </button>
                                <ErrorMessage name="confirm_password" component="div" className="text-red-500 font-medium text-xs m-2" />
                            </div>
                        </div>
                        <div className='text-center p-6 py-4'>
                            <div>
                                {
                                    status.isComplete && <p className={` mb-2 font-medium text-center ${status.isSuccess ? "text-green-500" : "text-red-500"}`}>  {status.isSuccess ? "Successfully updated." : "Something went wrong."} </p>
                                }
                            </div>
                            <button type="submit" className="bg-black text-white w-full  h-11 font-semibold text-base  shadow-2xl" disabled={isLoading} >
                                {
                                    isLoading ? <div className="loader-container">
                                        <div className="loader"></div>
                                    </div> : <span> Change Password</span>
                                }
                            </button>
                        </div>
                        <div className='text-center mb-5'>
                            <button type="button" onClick={() => { navigate(`/${dentalOfficeName}/forgot-password`) }}> Forgot Password? </button>
                        </div>
                    </Form>
                )}
            </Formik>


        </div>
    )
}

export default ChangePassword