import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import Lottie from "lottie-react"
import toothlensLogo from "../../assets/illustrations/Toothlens_logo.png"
// import activeNotification from "../../assets/Icons/Active.png"
// import filter from "../../assets/Icons/filter.png"
import menu from "../../assets/Icons/Menu.png"
import successAnimation from "../../assets/animations/Toast Confirmation Success.json"
import failureAnimation from "../../assets/animations/Toast Confirmation Failed.json"
import { downloadPdf, readReports } from "../../Services/APIs/report"
import ReportSkeleton from "../UI/Skeleton/reportsSkeleton"
import { addReports } from "../../Store/reports"
import calculateScore from "./calculateProgress"
import Report from "./report"

const Reports = () => {
    const { dentalOfficeName } = useParams();

    const [isDownloadStarted, setDownloadStart] = useState(false)
    const [pdfLoading, setPdfLoading] = useState(false)
    const [isPdfSuccess, setPdfSuccess] = useState(true)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { reportsData, fetched } = useSelector((state) => state.reports)
    const [isLoading, setLoading] = useState(false)
    const [isError, setError] = useState(false)

    useEffect(() => {
        !fetched && getReports()

    }, [])

    const getReports = async () => {
        setLoading(true)
        setError(false)
        await readReports().then((response) => {
            const reports = response.data.data
            const updatedReports = calculateScore(reports)

            updatedReports.reverse()
            dispatch(addReports(updatedReports))
            setLoading(false)

        }).catch((error) => {
            setLoading(false)
            setError(true)
        })

    }

    const handlePdfDownload = async (file) => {
        setDownloadStart(true)
        setPdfLoading(true)
        if (file) {
            await downloadPdf({ file }).then((response) => {
                const url = response.data?.data?.info
                const link = document.createElement("a");
                link.href = url;
                link.download = file;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                setPdfSuccess(true)
                setPdfLoading(false)
                setTimeout(() => {
                    setDownloadStart(false)
                    setPdfSuccess(false)
                }, 2000)

            }).catch((error) => {
                console.log("error", error)
                setPdfSuccess(false)
                setPdfLoading(false)
                setTimeout(() => {
                    setDownloadStart(false)
                    setPdfSuccess(false)
                }, 3100)
            })

        } else {
            setDownloadStart(true)
            setPdfLoading(false)
            setPdfSuccess(false)
            setTimeout(() => {
                setDownloadStart(false)

            }, 3100)
        }
    }

    const Header = () => {
        return (
            <div className="flex justify-between p-3">
                <button onClick={() => { navigate(`/${dentalOfficeName}/dashboard`) }}> <img src={toothlensLogo} alt="logo" className="h-8 w-24" /> </button>
                <div>
                    <button onClick={() => { navigate(`/${dentalOfficeName}/menu`) }}>  <img src={menu} alt="menu" className="w-8 h-8 ms-3" /> </button>

                </div>
            </div>
        )
    }



    return (
        <div className="h-full flex flex-col">
            <Header />
            <div className="flex justify-center">
                {
                    isDownloadStarted ? pdfLoading ?
                        <p className=" py-2.5  w-52 bg-green-300 text-center">Download in progress</p> :
                        <Lottie animationData={isPdfSuccess ? successAnimation : failureAnimation} style={{ width: 200 }} /> : null
                }

            </div>
            <div className="mb-4">
                <div className="mt-4 flex justify-between px-4">
                    <p className="text-2xl">Reports</p>
                    {/* <button type="button" onClick={() => { console.log("filters clicked") }} className="flex items-center ">
                        <img src={filter} alt="filter" className=" h-4 mx-3" />
                        <span className="text-sm font-bold">Filters</span>
                    </button> */}
                </div>
            </div>
            {isError ?
                <p className=" text-red-600 p-5 text-center">Something went wrong getting your reports please try again later.</p> :
                isLoading ?
                    <>
                        {
                            [...Array(7)].map((_, i) => <ReportSkeleton key={i} />)
                        }
                    </>
                    :
                    <div className="flex-grow overflow-auto">
                        {
                            reportsData.length > 0 ?
                                reportsData.map((eachReport, i) => <Report key={i} data={eachReport} handlePdfDownload={handlePdfDownload} />)
                                : <p className="py-5 text-center">No reports</p>
                        }
                    </div>
            }
        </div>
    )
}


export default Reports