import React from "react";
import TpScanCarousel from "./tpScanCarousel";
import TpProgress from "./tpProgress";
import TpInfo from "./tpInfo";
import BookAppointment from "./bookAppointment";
import DownloadPdf from "./downloadPdf";

const BernFlow = () => {
  return (
    <div>
      <BookAppointment />
      <TpScanCarousel />
      <div className="border to-gray-300 mt-5  mx-6 pb-3">
        <TpProgress />
        <TpInfo />
      </div>
      <DownloadPdf />
    </div>
  );
};

export default BernFlow;
