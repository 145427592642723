const calculateProgress = (previous, latest) => {
    const previous_overall_score = previous?.report_score?.overall_score ?? 0
    const current_overall_score = latest?.report_score?.overall_score ?? 0

    const previous_score = Math.min(previous_overall_score, 100)
    const current_score = Math.min(current_overall_score, 100)

    if (previous_score === 0) {
        if (current_score > previous_score) {
            return (-1) * current_score
        } else {
            return 0

        }
    } else {
        return Math.round(((previous_score - current_score) / previous_score) * 100)
    }

}

const calculateScore = (reports) => {

    const updatedReports = reports.map((eachReport, i) => {
        const { createdAt, report_score, _id, pdf_url } = eachReport

        if (i === 0) {
            const type = "initial"
            const score = report_score.overall_score
            return { _id, score, type, createdAt, pdf_url }
        } else {
            const type = "comparison"
            const score = report_score.overall_score
            const progress = calculateProgress(reports[i - 1], eachReport)
            const status = progress > 0 ? "Improved" : "Declined"
            const percentage = Math.abs(progress)
            return { _id, score, type, status, percentage, createdAt, pdf_url }
        }
    })

    return updatedReports

}

export default calculateScore