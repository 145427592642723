import React, { useEffect, useRef, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { ValidateOtp, forgotPassword } from "../../../Services/APIs/authentication"
import BackButton from "../../BackButton"

const Otp = (props) => {
    const { dentalOfficeName } = useParams();

    const navigate = useNavigate()
    const [info, setInfo] = useState()
    const otpPositions = [0, 1, 2, 3]
    const inputRefs = useRef([])
    const [otp, setOtp] = useState(['', '', '', ''])
    const [isLoading, setLoading] = useState(false)
    const [resendCodeLoading, resendCodesetLoading] = useState(false)
    const [error, setError] = useState('')

    useEffect(() => {
        const data = sessionStorage.getItem("info")
        data ? setInfo(JSON.parse(data)) : navigate(`/${dentalOfficeName}`)

    }, [])



    const handleOtpChange = (event, i) => {
        const value = event.target.value;
        if (value !== " " && (i >= 0)) {
            setOtp(prevState => {
                const newState = [...prevState]
                newState[i] = value
                return newState
            })

        }

        if (value && i < otp.length - 1) { inputRefs.current[i + 1].focus() }

    }

    const handleKeyDown = (event, i) => {
        if (event.key === "Backspace" && i > 0 && otp[i].length === 0) {
            inputRefs.current[i - 1].focus()
        }

        setOtp(prevState => {
            const newState = [...prevState]
            newState[i] = ''
            return newState
        })

    }

    const verifyCode = async values => {
        try {
            const verification_code = otp.join('')
            setLoading(true)
            setError('')
            await ValidateOtp({ email: info.email, verification_code, new_sign_in: info.new_sign_in }).then((response) => {
                setLoading(false)
                if (info.new_sign_in) {
                    sessionStorage.removeItem("info")
                    navigate(`/${dentalOfficeName}/login`, { replace: true })
                } else {
                    navigate(`/${dentalOfficeName}/reset-password`, { replace: true })
                }
            }).catch((error) => {
                setLoading(false)
                setError(error.response?.data?.message || 'An unexpected error occurred')
            })
        } catch (err) {
            setLoading(false)
            setError('An unexpected error occurred')
        }

    }

    const resendCode = () => {
        if (info) {
            resendCodesetLoading(true)
            setError('')
            forgotPassword({ email: info.email }).then((response) => {
                resendCodesetLoading(false)
            }).catch((error) => {
                resendCodesetLoading(false)
                setError(error.response?.data?.message || 'An unexpected error occurred')
            })
        } else {
            setError('An unexpected error occurred')
        }

    }

    return (
        <div className="h-full">
            <BackButton onClick={() => { window.history.back() }} />
            <div className="mx-6 flex flex-col justify-center h-2/3">
                <div className="mx-4">
                    <h1 className="font-normal text-39 leading-9"> Verification </h1>
                    <h1 className="font-normal text-39 "> Code </h1>
                    <p className="mt-4  font-medium text-base opacity-60">Enter verification code</p>
                    <div className="flex justify-center">
                        <div className='mt-10 py-2 flex justify-between w-full min-w-60 max-w-80'>
                            {
                                otpPositions.map((eachPosition, i) => {
                                    return (
                                        <input
                                            key={i}
                                            ref={(ref) => (inputRefs.current[i] = ref)}
                                            type="number"
                                            value={otp[i]}
                                            onChange={(event) => handleOtpChange(event, i)}
                                            onKeyDown={(event) => handleKeyDown(event, i)}
                                            className=" h-14 w-14 border text-base border-black text-center" />
                                    )
                                })
                            }
                        </div>
                    </div>

                </div>

                <div className='text-center mt-14'>
                    {error && <p className="text-red-500 font-medium text-xs m-2"> {error}</p>}
                    <button
                        type="button"
                        className={`bg-black text-white w-full h-11 font-semibold text-base  shadow-2xl ${!otp.every(item => item !== '') && " bg-zinc-400"}`}
                        disabled={!otp.every(item => item !== '')}
                        onClick={verifyCode}
                    >
                        {
                            isLoading ? <div className="loader-container">
                                <div className="loader"></div>
                            </div> : <span>Verify Code</span>
                        }
                    </button>
                    <button
                        type="button"
                        className="mt-5 bg-white text-black font-normal text-base w-full h-11 border border-black "
                        disabled={resendCodeLoading}
                        onClick={resendCode}>
                        {
                            resendCodeLoading ? <div className="loader-container">
                                <div className="loader dark-loader"></div>
                            </div> : <span>  Resend Code </span>
                        }

                    </button>
                </div>





            </div >
            <div className="absolute bottom-7  w-screen">
                <p className="text-center text-black ">
                    <span className="opacity-60"> Don’t have a account</span>
                    <button className="font-bold pl-2" onClick={() => navigate(`/${dentalOfficeName}/sign-in`)}>  Sign In </button>
                </p>
            </div>
        </div >
    )
}

export default Otp